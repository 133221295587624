// react
import React, { useState, useEffect, useRef, useCallback } from "react";

// openlayers
import Map from "ol/Map";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import Cluster from "ol/source/Cluster";
import OSM from "ol/source/OSM";
import XYZ from "ol/source/XYZ";
import KML from "ol/format/KML.js";
import { fromLonLat, Projection, toLonLat } from "ol/proj.js";
import { Draw, Modify, Select, Snap } from "ol/interaction.js";
import { defaults as defaultControls } from "ol/control.js";
import "./BasicMap.css";
import { Collection, Feature, Overlay } from "ol";
import { LineString, MultiPoint, Point } from "ol/geom";
import Icon from "ol/style/Icon";
import Text from "ol/style/Text";
import { Fill, RegularShape, Stroke, Style } from "ol/style.js";
import VectorTileLayer from "ol/layer/VectorTile";
import VectorTileSource from "ol/source/VectorTile";
import TileWMS from "ol/source/TileWMS.js";
import place from "../../assets/place_24.svg";
import CircleStyle from "ol/style/Circle";
import { createBox } from 'ol/interaction/Draw.js';
import { useMap } from "../../context/MapProvider";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  MenuItem,
  Popover,
  Radio,
  RadioGroup,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Add,
  CancelOutlined,
  Close,
  GpsFixed,
  GpsNotFixed,
  HomeOutlined,
  Layers,
  PolylineOutlined,
  Save,
  Search,
  Straighten,
  TimelineOutlined,
  ZoomIn,
} from "@mui/icons-material";
import { useTheme } from "@emotion/react";
import { always, click, never } from "ol/events/condition.js";
import {v4 as uuidv4} from 'uuid';
import { callApiAsync, getAreaSizeString, getLengthString, rgbToHexA } from "../../js/helper";
import { createEmpty, extend, getCenter } from "ol/extent";
import { getArea } from 'ol/sphere.js';
import { easeOut, linear } from 'ol/easing';
import DrawControls from "./DrawControls";
import { useTableController } from "../../context/TableProvider";
import mapBgIcon from "../../assets/tableIcons/mapBGIcon.png";
import kmlMarker from "../../assets/tableIcons/kmlMarker.png";
import { useServerData } from "../../providers/DataProvider";
import GeoJSON from 'ol/format/GeoJSON.js';
import geojsonObject from "../../assets/Abteilungen.json";
import geojsonvt from 'geojson-vt';
import { useSignalR } from "../../providers/SignalRProvider";
import { evaluateGeometryStyle, evaluateMarkerStyle } from "./mapStyles";

import { buffer, lineString } from '@turf/turf';
import myFunction from "./myFunction.txt";


const { convertXML } = require("simple-xml-to-json")


function AddFavoritButton(props) {
  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState("");
  function saveFavorit() {
    let newFavorit = {
      name: name,
      id: uuidv4(),
      enabledBaseLayer: props.enabledBaseLayer,
      enabledLayers: props.enabledLayers
    };
    props.onNewFavorit(newFavorit);
    setOpen(false);
  }

  return(
    !open ?
      <IconButton style={{height: 36, width: 36, alignSelf: "center"}} onClick={() => setOpen(true)}><Add/></IconButton>
    : <div style={{display: "flex", gap: "8px"}}>
      <TextField size="small" onChange={(e) => setName(e.target.value)}></TextField>
      <IconButton style={{height: 36, width: 36, alignSelf: "center"}} onClick={() => saveFavorit()}><Save/></IconButton>
    </div>
  );
}

function LayerSelection(props) {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [dlgOpen, setDlgOpen] = React.useState(false);
  const open = Boolean(anchorEl);

  const { 
    cfgs,
    layers,
    favLayers,
    enabledBaseLayer,
    enabledLayers,
    enabledFavLayer,
    setFavLayers,
    setEnabledBaseLayer,
    setEnabledLayers,
    setEnabledFavLayer,
    saveUserSetting,
    saveCompleteUserSettings
  } = useServerData();


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  const onNewFavorit = useCallback((newFavorit) => {
    let newFavLayerArr = [...favLayers];
    newFavLayerArr.push(newFavorit);
    setFavLayers(newFavLayerArr);
    saveUserSetting("favLayers", newFavLayerArr);
  }, [favLayers]);

  const onBaseLayerChanged = useCallback((key, value) => {
    setEnabledBaseLayer(key);
    saveUserSetting("enabledBaseLayer", key);
    setEnabledFavLayer(null);
  }, [enabledBaseLayer])

  const onFavLayerChanged = useCallback((favorit, value) => {
    setEnabledFavLayer(favorit.id);
    setEnabledLayers(favorit.enabledLayers);
    setEnabledBaseLayer(favorit.enabledBaseLayer);

    let newUserSettings = {...props.userSettings};
    newUserSettings["enabledFavLayer"] = favorit.id;
    newUserSettings["enabledBaseLayer"] = favorit.enabledBaseLayer;
    newUserSettings["enabledLayers"] = favorit.enabledLayers;
    saveCompleteUserSettings(newUserSettings);
  }, [enabledFavLayer, enabledLayers, enabledBaseLayer, props.userSettings])

  const onLayerChanged = useCallback((key, value) => {
    let newEnabledLayers = [...enabledLayers];
    let index = newEnabledLayers.findIndex((layer) => layer === key);
    if(index >= 0) {
      newEnabledLayers.splice(index, 1);
    } else {
      newEnabledLayers.push(key);
    }
    setEnabledLayers(newEnabledLayers);
    saveUserSetting("enabledLayers", newEnabledLayers);
  }, [enabledLayers])
  

  return (
    <>
      <IconButton
        onClick={handleClick}
        style={{
          position: "absolute",
          backgroundColor: theme.palette.background.default,
          borderRadius: 12,
          right: "16px",
          bottom: props.tableSize + 16,
          zIndex: 1,
        }}
      >
        <Layers />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        style={{ width: "300px" }}
        sx={{
          width: "700px", 
          minWidth: "300px",
          '& .MuiPopover-paper': {
            borderRadius: "12px"
          }
        }}
      >
        <Typography sx={{ paddingInline: 2, paddingBlock: 1 }}>
          Layerauswahl
          <IconButton
            onClick={() => {
              handleClose();
              setDlgOpen(true);
            }}
            sx={{ alignItems: "flex-ends" }}
            size="small"
          >
            <ZoomIn />
          </IconButton>
        </Typography>
        <Divider />
        <Box sx={{ p: 2 }} style={{ overflowX: "hidden", overflowY: "scroll", height: "378px" }}>
          <RadioGroup>
            <Divider textAlign="center" style={{ marginBottom: "4px" }}>
              Favoriten
            </Divider>
            {favLayers ?
              favLayers?.map((option) => {
                let checked = enabledFavLayer?.includes(option.id);
                return (
                  <FormControlLabel
                    key={option.layerKey}
                    control={<Radio />}
                    label={option.name}
                    checked={checked}
                    onClick={(e, value) => onFavLayerChanged(option, value)}
                  />
                );
            }) : null}

            <AddFavoritButton
              enabledBaseLayer={enabledBaseLayer}
              enabledLayers={enabledLayers}
              onNewFavorit={onNewFavorit}
            />

            <Divider textAlign="center" style={{ marginBottom: "4px" }}>
              Grundkarte
            </Divider>
            {layers?.map((option) => {
              if (option.isBaseLayer) {
                let checked = enabledBaseLayer?.includes(option.layerKey);
                return (
                  <FormControlLabel
                    key={option.layerKey}
                    control={<Radio />}
                    label={option.layerName}
                    checked={checked}
                    onClick={(e, value) => onBaseLayerChanged(option.layerKey, value)}
                  />
                );
              }
            })}
            <Divider
              textAlign="center"
              style={{ marginTop: "16px", marginBottom: "4px" }}
            >
              Aufleger
            </Divider>
            {layers?.map((option) => {
              if (!option.isBaseLayer) {
                let checked = enabledLayers?.includes(option.layerKey);
                return (
                  <FormControlLabel
                    key={option.layerKey}
                    control={<Switch />}
                    label={option.layerName}
                    checked={checked}
                    onChange={(e, value) => onLayerChanged(option.layerKey, value)}
                  />
                );
              }
            })}
            <Divider
              textAlign="center"
              style={{ marginTop: "16px", marginBottom: "4px" }}
            >
              POI Aufleger
            </Divider>
            {cfgs.map((cfg) => {
              return (
                <MenuItem>
                  <Typography style={{textOverflow: "ellipsis", overflow: "hidden"}}>{cfg.tableName}</Typography>
                </MenuItem>
              );
            })}
          </RadioGroup>
        </Box>
      </Popover>

      {
        <Dialog
          sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
          maxWidth="xs"
          open={dlgOpen}
        >
          <DialogTitle>Layerauswahl</DialogTitle>
          <DialogContent dividers>
            <RadioGroup>
              <Divider textAlign="center" style={{ marginBottom: "4px" }}>
                Grundkarte
              </Divider>
              {layers.map((option) => {
                if (option.isBaseLayer) {
                  let checked = enabledBaseLayer?.includes(option.layerKey);
                  return (
                    <FormControlLabel
                      key={option.layerKey}
                      control={<Radio />}
                      label={option.layerName}
                      checked={checked}
                      onClick={(e, value) => onBaseLayerChanged(option.layerKey, value)}
                    />
                  );
                }
              })}
              <Divider
                textAlign="center"
                style={{ marginTop: "16px", marginBottom: "4px" }}
              >
                Aufleger
              </Divider>
              {layers.map((option) => {
                if (!option.isBaseLayer) {
                  let checked = enabledLayers?.includes(option.layerKey);
                  return (
                    <FormControlLabel
                      key={option.layerKey}
                      control={<Switch />}
                      label={option.layerName}
                      checked={checked}
                      onChange={(e, value) => onLayerChanged(option.layerKey, value) }
                    />
                  );
                }
              })}
              <Divider
                textAlign="center"
                style={{ marginTop: "16px", marginBottom: "4px" }}
              >
                POI Aufleger
              </Divider>
              {layers.map((option) => {
                if (!option.isBaseLayer) {
                  let checked =  enabledLayers.includes(option.layerKey);
                  return (
                    <FormControlLabel
                      key={option.layerKey}
                      control={<Switch />}
                      label={option.layerName}
                      checked={checked}
                      onChange={(e, value) => onLayerChanged(option.layerKey, value)}
                    />
                  );
                }
              })}
            </RadioGroup>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDlgOpen(false)}>Ok</Button>
          </DialogActions>
        </Dialog>
      }
    </>
  );
}

function FollowLocationControl(props) {
  const theme = useTheme();

  const { lastNotification } = useSignalR();
  const { buddies } = useServerData();
  
  const [active, setActive] = useState(false);
  const drawLocationRef = useRef();

  const watchID = useRef();
  const initialPosRef = useRef([null, null]);
  var posChanged = false;

  /*function addLocationMarker(lat, lon) {
    if (drawLocationRef.current == null) {
      const source = new VectorSource({ wrapX: false });
      const lassoVector = new VectorLayer({
        source: source,
        style: new Style({
          image: !posChanged ? new Icon({
            width: 36,
            anchor: [0.5, 1],
            src: place,
          }) : new Icon({
            width: 36,
            anchor: [0.5, 0.5],
            src: circleIcon,
          })
        }),
        zIndex: 999
      });
      drawLocationRef.current = lassoVector;
      if (props.mapRef.current != null) {
        props.mapRef.current.addLayer(lassoVector);
      }
    }

    const feature = new Feature({
      geometry: new Point(fromLonLat([lon, lat])),
    });

    if (drawLocationRef.current) {
      drawLocationRef.current.getSource().clear();
      feature.setStyle(
        !posChanged ? 
        new Style({
          image: new Icon({
            width: 36,
            anchor: [0.5, 1],
            src: place,
          })
        }) : [
          new Style({
            image: new CircleStyle({
              radius: 8,
              fill: new Fill({
                color: 'white',
              }),
            })
          }),
          new Style({
            image: new CircleStyle({
              radius: 6,
              fill: new Fill({
                color: '#009900',
              }),
            })
          })
        ]
      );
      drawLocationRef.current.getSource().addFeature(feature);
    }

    setTimeout(() => {
      if(props.mapRef) {
        props.mapRef.current.getView().animate({
          center: fromLonLat([
            lon,
            lat,
          ]),
          duration: 500,
          easing: easeOut
        });
      }
    }, 1);
  }*/

  async function addLocationMarker(lat, lon, user) {  
    if (drawLocationRef.current == null) {
      const source = new VectorSource({ wrapX: false });
      const lassoVector = new VectorLayer({
        source: source,
        style: [
          new Style({
            image: new RegularShape({
              points: 4,
              radius: 30,
              angle: Math.PI / 4,
              fill: new Fill({
                color: '#fff',
              }),
            })
          }),
          new Style({
            image: new Icon({
              width: 36,
              anchor: [0.5, 0.5],
              src: user?.profilePictureBase64,
            })
          })
        ],
        /*style: new Style({
          image: !posChanged ? new Icon({
            width: 36,
            anchor: [0.5, 1],
            src: place,
          }) : new Icon({
            width: 36,
            anchor: [0.5, 0.5],
            src: circleIcon,
          })
        }),*/
        zIndex: 999
      });
      drawLocationRef.current = lassoVector;
      if (props.mapRef.current != null) {
        props.mapRef.current.addLayer(lassoVector);
      }
    }

    const feature = new Feature({
      geometry: new Point(fromLonLat([lon, lat])),
    });

    if (drawLocationRef.current) {
      drawLocationRef.current.getSource().clear();
      /*feature.setStyle(
        !posChanged ? 
        new Style({
          image: new Icon({
            width: 36,
            anchor: [0.5, 1],
            src: place,
          })
        }) : [
          new Style({
            image: new CircleStyle({
              radius: 8,
              fill: new Fill({
                color: 'white',
              }),
            })
          }),
          new Style({
            image: new CircleStyle({
              radius: 6,
              fill: new Fill({
                color: '#009900',
              }),
            })
          })
        ]
      );*/
      drawLocationRef.current.getSource().addFeature(feature);
    }

    setTimeout(() => {
      if(props.mapRef) {
        props.mapRef.current.getView().animate({
          center: fromLonLat([
            lon,
            lat,
          ]),
          duration: 500,
          easing: linear
        });
      }
    }, 1);
  }

  function removeLocationMarker() {
    if (drawLocationRef.current) {
      drawLocationRef.current.getSource().clear();
    }
  }

  function showLocation(position) {
    var latitude = position.coords.latitude;
    var longitude = position.coords.longitude;

    if(!initialPosRef.current[0] && !initialPosRef.current[1]) {
      initialPosRef.current = [latitude, longitude];
    } else {
      if(initialPosRef.current[0] != latitude && initialPosRef.current[1] != longitude) {
        posChanged = true;
      }
    }

    addLocationMarker(latitude, longitude);
  }
 
  function errorHandler(err) {
    if(err.code == 1) {
      alert("Error: Access is denied!");
    } else if( err.code == 2) {
      //alert("Error: Position is unavailable!");
    }
  }

  useEffect(() => {
    if(watchID.current != null) {
      navigator.geolocation.clearWatch(watchID.current);
      removeLocationMarker();
    }
    if(active) {
      //navigator.geolocation.getCurrentPosition(showLocation, errorHandler, {enableHighAccuracy: true, maximumAge: 10000})
      watchID.current = navigator.geolocation.watchPosition(showLocation, errorHandler);
      console.log(watchID.current);
    }
  }, [active]);



  //ONLY TESTING LOCATION
  useEffect(() => {
    if(lastNotification?.type == "LocationShared") {
      var latitude = lastNotification.value.lon;
      var longitude = lastNotification.value.lat;

      if(!initialPosRef.current[0] && !initialPosRef.current[1]) {
        initialPosRef.current = [latitude, longitude];
      } else {
        if(initialPosRef.current[0] != latitude && initialPosRef.current[1] != longitude) {
          posChanged = true;
        }
      }

      let user = buddies.find((i) => i.id == lastNotification.value.userId);

      console.log(user)

      addLocationMarker(latitude, longitude, user);
    }

  }, [lastNotification]);



  return (
        <div
        style={{
          position: "absolute",
          display: "flex",
          flexDirection: "column",
          backgroundColor: theme.palette.background.default,
          borderRadius: 12,
          color: "white",
          right: "16px",
          top: "107px",
          zIndex: 1,
        }}
      >
        <Tooltip title={"Position verfolgen"} placement="left">
          <span>
            <IconButton onClick={() => setActive(!active)}>
              {active ? <GpsFixed /> : <GpsNotFixed />}
            </IconButton>
          </span>
        </Tooltip>
      </div>
  );
}

function SearchControls(props) {
  const theme = useTheme();
  
  const [searchText, setSearchText] = useState("");
  const [updateIndex, setUpdateIndex] = useState(0);
  const [foundPlaces, setFoundPlaces] = useState([]);
  const drawLocationRef = useRef();

  function zoomMapToLocation(location) {
    if(props.mapRef) {
      var extent = createEmpty();
      let bb = [...location.boundingbox];
      let pos1 = fromLonLat([location.boundingbox[2], location.boundingbox[0]]);
      let pos2 = fromLonLat([location.boundingbox[3], location.boundingbox[1]]);
      bb[0] = pos1[0];
      bb[1] = pos1[1];
      bb[2] = pos2[0];
      bb[3] = pos2[1];
      extend(extent, bb);
      var animationOptions = {
        duration: 750,
        padding: [100, 100, 100, 100],
        maxZoom: 16
      };
      props.mapRef.current.getView().fit(extent, animationOptions);
      addLocationMarker(location.lat, location.lon);
      setFoundPlaces([]);
    }
  }

  function addLocationMarker(lat, lon) {
    if (drawLocationRef.current == null) {
      const source = new VectorSource({ wrapX: false });
      const lassoVector = new VectorLayer({
        source: source,
        style: new Style({
          image: new Icon({
            width: 36,
            anchor: [0.5, 1],
            src: place,
          })
        }),
        zIndex: 999
      });
      drawLocationRef.current = lassoVector;
      if (props.mapRef.current != null) {
        props.mapRef.current.addLayer(lassoVector);
      }
    }

    const feature = new Feature({
      geometry: new Point(fromLonLat([lon, lat])),
    });

    if (drawLocationRef.current) {
      drawLocationRef.current.getSource().clear();
      drawLocationRef.current.getSource().addFeature(feature);
    }
  }

  function removeLocationMarker() {
    setFoundPlaces([]);
    setSearchText("");
    if (drawLocationRef.current) {
      drawLocationRef.current.getSource().clear();
    }
  }

  function handleClick() {
    if(hasFeature) {
      removeLocationMarker();
    } else {
      if(foundPlaces.length > 0) zoomMapToLocation(foundPlaces[0])
    }
  }

  function handleHomeLocation() {
    props.mapRef.current.getView().animate({
      center: fromLonLat([
        10.0,
        50.0,
      ]),
      zoom: 6,
      duration: 1000,
    });
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      let requestOptions = {
        method: "GET",
      };
      callApiAsync("https://nominatim.openstreetmap.org/search?format=json&q=" + searchText, requestOptions, (resp) => {
        setFoundPlaces(resp);
      });
    }, 200)
    return () => clearTimeout(delayDebounceFn)
  }, [searchText, updateIndex])

  let hasFeature = drawLocationRef.current ? drawLocationRef.current.getSource().getFeatures().length > 0 : false;

  return (
    <>
      <div
        style={{
          position: "absolute",
          display: "flex",
          flexDirection: "column",
          backgroundColor: theme.palette.background.default,
          borderRadius: "12px",
          overflow: "hidden",
          width: "250px",
          color: "white",
          left: props.appViewSize + 16 + 72 + (props.drawControlsOpen ? 340 : 0),
          top: "16px",
        }}
      >
        <div style={{width: "100%", display: "flex"}}>
          <TextField 
            value={searchText}
            size="small"
            fullWidth
            placeholder="Suchen"
            variant="outlined"
            autoComplete='off'
            onChange={(e) => setSearchText(e.target.value)}
            onFocus={(e) => setUpdateIndex(updateIndex + 1)}
            onKeyDown={(e) => (e.keyCode==13 && foundPlaces.length > 0) ? zoomMapToLocation(foundPlaces[0]) : null}
            sx={{
              "& fieldset": { border: 'none' },
            }}
          />
          <Tooltip title={!hasFeature ? "Ortssuche" : "Abbrechen"}>
            <span>
              <IconButton onClick={handleClick}>
                {hasFeature ? <Close /> : <Search />}
              </IconButton>
            </span>
          </Tooltip>
          <Divider orientation="vertical" variant='middle' flexItem/>
          <Tooltip title={"Heimansicht"}>
            <span>
              <IconButton onClick={handleHomeLocation}>
                <HomeOutlined />
              </IconButton>
            </span>
          </Tooltip>
        </div>
        {foundPlaces.length > 0 ? 
        <div>
          <Divider style={{marginInline: 8}}/>
          <Typography style={{color: "#777", fontSize: 12, paddingInline: 16, paddingBlock: 4}}>Ergebnisse:</Typography>
          <div style={{width: "100%", maxHeight: "500px", overflow: "hidden", overflowY: "scroll"}}>
            <List style={{padding: 0}}>
            {foundPlaces.map((place) => {
              return(
                <ListItemButton key={place.place_id} onClick={() => zoomMapToLocation(place)}>
                  <ListItemText style={{color: "black"}} primary={place.display_name} />
                </ListItemButton>
              );
            })}
            </List>
          </div>
        </div> : null}
      </div>
    </>
  );
}

function MeasureControls(props) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [toolSelected, setToolSelected] = useState(false);

  const measureVectorLayerRef = useRef();
  const measureModifyRef = useRef();
  const measureDrawRef = useRef();
  const measureSnapRef = useRef();

  function toggleOpen() {
    if(open) removeMeasurement();
    setOpen(!open);
    console.log(open);
  }

  const style = new Style({
    fill: new Fill({
      color: 'rgba(255, 255, 255, 0.2)',
    }),
    stroke: new Stroke({
      color: 'rgba(255, 255, 255, 1)',
      lineDash: [10, 10],
      width: 2,
    }),
    image: new CircleStyle({
      radius: 5,
      stroke: new Stroke({
        color: 'rgba(255, 255, 255, 0.7)',
      }),
      fill: new Fill({
        color: 'rgba(255, 255, 255, 0.2)',
      }),
    }),
  });
  
  const labelStyle = new Style({
    text: new Text({
      font: '14px Calibri,sans-serif',
      fill: new Fill({
        color: 'rgba(255, 255, 255, 1)',
      }),
      backgroundFill: new Fill({
        color: 'rgba(0, 153, 0, 0.7)',
      }),
      padding: [3, 3, 3, 3],
      textBaseline: 'bottom',
      offsetY: -15,
    }),
    image: new RegularShape({
      radius: 8,
      points: 3,
      angle: Math.PI,
      displacement: [0, 10],
      fill: new Fill({
        color: 'rgba(0, 153, 0, 0.7)',
      }),
    }),
  });
  
  const modifyStyle = new Style({
    image: new CircleStyle({
      radius: 5,
      stroke: new Stroke({
        color: 'rgba(0, 153, 0, 0.7)',
      }),
      fill: new Fill({
        color: 'rgba(0, 153, 0, 0.4)',
      }),
    }),
    text: new Text({
      font: '12px Calibri,sans-serif',
      fill: new Fill({
        color: 'rgba(255, 255, 255, 1)',
      }),
      backgroundFill: new Fill({
        color: 'rgba(0, 153, 0, 0.7)',
      }),
      padding: [2, 2, 2, 2],
      textAlign: 'left',
      offsetX: 15,
    }),
  });
  
  const segmentStyle = new Style({
    text: new Text({
      font: '12px Calibri,sans-serif',
      fill: new Fill({
        color: 'rgba(255, 255, 255, 1)',
      }),
      backgroundFill: new Fill({
        color: 'rgba(0, 153, 0, 0.4)',
      }),
      padding: [2, 2, 2, 2],
      textBaseline: 'bottom',
      offsetY: -12,
    }),
    image: new RegularShape({
      radius: 6,
      points: 3,
      angle: Math.PI,
      displacement: [0, 8],
      fill: new Fill({
        color: 'rgba(0, 153, 0, 0.4)',
      }),
    }),
  });
  
  const segmentStyles = [segmentStyle];
  
  let tipPoint;
  
  function styleFunction(feature, segments, drawType) {
    const styles = [];
    const geometry = feature.getGeometry();
    const type = geometry.getType();
    let point, label, line;
    if (!drawType || drawType === type || type === 'Point') {
      styles.push(style);
      if (type === 'Polygon') {
        point = geometry.getInteriorPoint();
        label = getAreaSizeString(geometry) + "\n" + getLengthString(geometry);
        line = new LineString(geometry.getCoordinates()[0]);
      } else if (type === 'LineString') {
        point = new Point(geometry.getLastCoordinate());
        label = getLengthString(geometry);
        line = geometry;
      }
    }
    if (segments && line) {
      let count = 0;
      line.forEachSegment(function (a, b) {
        const segment = new LineString([a, b]);
        const label = getLengthString(segment);
        if (segmentStyles.length - 1 < count) {
          segmentStyles.push(segmentStyle.clone());
        }
        const segmentPoint = new Point(segment.getCoordinateAt(0.5));
        segmentStyles[count].setGeometry(segmentPoint);
        segmentStyles[count].getText().setText(label);
        styles.push(segmentStyles[count]);
        count++;
      });
    }
    if (label) {
      labelStyle.setGeometry(point);
      labelStyle.getText().setText(label);
      styles.push(labelStyle);
    }
    return styles;
  }

  function addLineMeasure() {
    console.log("addLineMeasure");
    addMeasure("LineString");
    setToolSelected(true);
  }

  function addPolyMeasure() {
    console.log("addPolyMeasure");
    addMeasure("Polygon");
    setToolSelected(true);
  }

  function addMeasure(type) {
    if (measureVectorLayerRef.current == null) {
      const source = new VectorSource({ wrapX: false });
      const lassoVector = new VectorLayer({
        source: source,
        style: function (feature) {
          return styleFunction(feature, true);
        },
        zIndex: 999
      });
  
      measureVectorLayerRef.current = lassoVector;
  
      const modify = new Modify({
        source: source,
        style: modifyStyle,
        snapToPointer: true
      });
      measureModifyRef.current = modify;
  
      if (props.mapRef.current != null) {
        props.mapRef.current.addLayer(lassoVector);
      }
  
      let draw = new Draw({
        source: source,
        type: type,
        freehand: false,
        snapTolerance: 10,
        style: function (feature) {
          return styleFunction(feature, true, type);
        },
      });
  
      draw.on('drawstart', function () {
        source.clear();
        modify.setActive(false);
      });
      draw.on('drawend', function () {
        modifyStyle.setGeometry(tipPoint);
        modify.setActive(true);
        if(props.mapRef.current != null) props.mapRef.current.removeInteraction(measureDrawRef.current);
      });
  
      measureDrawRef.current = draw;
  
      if(props.mapRef.current != null) props.mapRef.current.addInteraction(draw);

      //Snap
      let featuresToSnap = [];
      if(props.mapRef.current) {
        let layers = props.mapRef.current.getAllLayers();
        layers.map((layer) => {
          if(layer.get("layerId")) {
            let features = layer.getSource().getFeatures();
            if(features) {
              featuresToSnap = featuresToSnap.concat(features);
            }
          }
        });

        console.log("featuresToSnap", featuresToSnap);

        const snap = new Snap({
          features: new Collection(featuresToSnap),
          edge: true,
          vertex: true
        });
        snap.setActive(true);
        props.mapRef.current.addInteraction(snap);
        props.mapRef.current.addInteraction(modify);
        measureSnapRef.current = snap;
      }

      props.onToggleActive(false);
    }
  }

  function removeMeasurement() {
    console.log("removeMeasurement");

    if (measureVectorLayerRef.current != null) {
      measureVectorLayerRef.current.getSource().clear();
      measureVectorLayerRef.current = null;
    }

    if(measureDrawRef.current) measureDrawRef.current.abortDrawing();
    if(props.mapRef.current != null) props.mapRef.current.removeInteraction(measureDrawRef.current);
    if(props.mapRef.current != null) props.mapRef.current.removeInteraction(measureModifyRef.current);
    if(props.mapRef.current != null) props.mapRef.current.removeInteraction(measureSnapRef.current);

    setToolSelected(false);
    props.onToggleActive(true);
  }

  return(
    <div
        style={{
          position: "absolute",
          display: "flex",
          flexDirection: "column",
          backgroundColor: theme.palette.background.default,
          borderRadius: 12,
          color: "white",
          right: "16px",
          top: "158px",
          zIndex: 1,
        }}
      >
        <Tooltip title={!open ? "Messen" : "Abbrechen"} placement="left">
          <span>
            <IconButton onClick={toggleOpen}>
              {open ? <CancelOutlined /> : <Straighten />}
            </IconButton>
          </span>
        </Tooltip>
        {open && !toolSelected ? (
          <>
            <Tooltip title="Linie" placement="left"><IconButton style={{color: props.selectedTool == "LineString" ? theme.palette.primary.main : null}} onClick={() => addLineMeasure()}><TimelineOutlined /></IconButton></Tooltip>
            <Tooltip title="Polygon" placement="left"><IconButton style={{color: props.selectedTool == "Polygon" ? theme.palette.primary.main : null}} onClick={() => addPolyMeasure()}><PolylineOutlined /></IconButton></Tooltip>
          </>
        ) : null}
      </div>
  );
}

function BasicMap(props) {
  const theme = useTheme();
  // set intial state
  const mapElement = useRef();
  const mapRef = useRef();

  const baseLayerRef = useRef();

  const popupRef = useRef();
  const popupDivRef = useRef();

  const posLayerRef = useRef();
  const vecLayerRef = useRef();

  const moveEndTimeoutRef = useRef();
  const [mapPos, setMapPos] = useState(null);

  const selectClickRef = useRef();

  const [map, setMap] = useState();

  const [baseSrc, setBaseSrc] = useState(
    new XYZ({
      url: "https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}",
      crossOrigin: "anonymous",
    })
  );

  const [popupData, setPopupData] = useState({type: null, data: []});

  const { tableController } = useTableController();

  const {
    serverSyncState,
    cfgs,
    activeCfgs,
    selectedCfg,
    styles,
    selectedStyles,
    dataEntries,
    mapConfigs,
    selectedPois,
    selectedBlobs,
    selectedRows,
    selectedFilters,
    layers,
    favLayers,
    enabledBaseLayer,
    enabledLayers,
    enabledFavLayer,

    selectedStyle,
    gisStyles,
    selectedTableRows,
    selectedTableFilters,
    hasMapLayer,
    dataEntriesByUniqueKey,
    selectedPoisByUniqueId,

    setServerSyncState,
    setCfgs,
    setActiveCfgs,
    setSelectedCfg,
    setStyles,
    setSelectedStyles,
    setDataEntries,
    setMapConfigs,
    setSelectedPois,
    setSelectedBlobs,
    setSelectedRows,
    setSelectedFilters,
    setFavLayers,
    setEnabledBaseLayer,
    setEnabledLayers,
    setEnabledFavLayer
  } = useServerData();




  function evaluateJavascript(geoJson) {

    let result = eval('function test() {return 100;} test();')

    console.log(result)


    geoJson.features.forEach((feature) => {
      let type = feature.properties.type;

      if(type == "skidroad") {
        var line = lineString(feature.geometry.coordinates)
        var bufferFeature = buffer(line, 2, { units: "meters" });
        bufferFeature.properties["stroke"] = "#FCDB00FF"
        bufferFeature.properties["stroke-width"] = 2
        bufferFeature.properties["fill"] = "#FCDB004D"
        console.log(bufferFeature)
        geoJson.features.push(bufferFeature)

        /*var bufferFeature2 = buffer(line, 5, { units: "meters" });
        bufferFeature2.properties["stroke"] = "#ffffffff"
        bufferFeature2.properties["stroke-width"] = 2
        bufferFeature2.properties["fill"] = "#ffffff44"
        console.log(bufferFeature2)
        geoJsonString.features.push(bufferFeature2)*/
      }
    })

    return geoJson;
  }









  //NEW SYSTEM CODE - DONE
  function drawPoisPosition() {
    if (mapRef.current == null) return;
    let layers = mapRef.current.getAllLayers();

    //Delete all unused layers
    for (let layer of layers) {
      let layerId = layer.get("layerId");
      let uniqueKey = layerId?.split("_")[0];
      if(layerId != null && !selectedPois[uniqueKey]) {
        mapRef.current.removeLayer(layer);
      }
    }

    for (let e of Object.entries(selectedPois)) {
      let uniqueKey = e[0];
      let entries = e[1];
      let config = cfgs.find((config) => config.uniqueKey == uniqueKey);

      let posLayer;
      for (let layer of layers) {
        if (layer.get("layerId") == uniqueKey) {
          posLayer = layer;
          posLayerRef.current = layer;
          continue;
        }
      }

      if (posLayer == null) {
        const vectorSource = new VectorSource();
        const clusterSource = new Cluster({
          distance: 30,
          minDistance: 20,
          source: vectorSource,
        });
        posLayer = new VectorLayer({
          source: clusterSource,
          opacity: 1,  //WHEN SOME GEOMETRY IS BEEN EDITED? ///TODO
          layerId: uniqueKey,
          zIndex: 999,
        });
        posLayerRef.current = posLayer;
        if (map != undefined) {
          mapRef.current.addLayer(posLayer);
        }
      }

      posLayerRef.current.getSource().setDistance(!mapConfigs[uniqueKey]?.noCluster ? 30 : 1);
      posLayerRef.current.getSource().setMinDistance(!mapConfigs[uniqueKey]?.noCluster ? 20 : 1);

      let features = [];
      let locationCol = config?.columns?.find((i) => i.fieldType == "Location");
      for (let entry of entries) {
        if (mapConfigs[uniqueKey]?.showIcons && locationCol && entry[locationCol?.key]?.lat && entry[locationCol?.key]?.long) {
          let feature = new Feature({
            geometry: new Point([
              entry ? entry[locationCol.key]?.long : 0,
              entry ? entry[locationCol.key]?.lat : 0,
            ]).transform("EPSG:4326", "EPSG:3857"),
            layerId: uniqueKey,
            tableIcon: config?.tableIcon,
            tableIconBgColor: config?.tableIconBgColor,
            iconLabelTemplate: config?.iconLabelTemplate,
            guid: entry._id,
          });
          feature.setProperties(entry);
          features.push(feature);
        }
      }

      if (posLayer != undefined) {
        posLayerRef.current.setStyle((features, resolution) => evaluateMarkerStyle(features, resolution, styles, selectedStyles[uniqueKey], mapConfigs));
      }

      if (posLayerRef.current) {
        posLayerRef.current.getSource().getSource().clear();
        posLayerRef.current.getSource().getSource().addFeatures(features);
      }
    }
  }

  function drawPoiGeometries() {
    if (mapRef.current == null) return;
    let layers = mapRef.current.getAllLayers();

    //Delete all unused layers
    for (let layer of layers) {
      let layerId = layer.get("layerId");
      let uniqueKey = layerId?.split("_")[0];
      if(layerId != null && !selectedPois[uniqueKey]) {
        mapRef.current.removeLayer(layer);
      }
    }

    for (let e of Object.entries(selectedPois)) {
      let uniqueKey = e[0];
      let entries = e[1];

      let config = cfgs.find((config) => config.uniqueKey == uniqueKey);
      let vectorOverlayKey = "map";
      if(config) {
        let vectorOverlay = config.columns?.find((row) => row.fieldType == "Map");
        if(vectorOverlay != null && vectorOverlay.columnKey != null) vectorOverlayKey = vectorOverlay.columnKey;
      }

      let vecLayer;

      for (let layer of layers) {
        if (layer.get("layerId") == uniqueKey + "_" + vectorOverlayKey) {
          vecLayer = layer;
          vecLayerRef.current = layer;
          continue;
        }
      }

      if (vecLayer == null) {
        const vectorSource = new VectorSource();
        vecLayer = new VectorLayer({
          source: vectorSource,
          opacity: 1,
          layerId: uniqueKey + "_" + vectorOverlayKey,
          zIndex: 998
        });
        vecLayerRef.current = vecLayer;
        if (map != undefined) {
          mapRef.current.addLayer(vecLayer);
        }
      }

      let features = [];
      if (entries != null) {
        let editedPoiGuid = drawVectorLayerRef.current ? drawVectorLayerRef.current.getSource().get("poiGuid") : "";
        entries.forEach((entry, idx) => {
          if (mapConfigs[uniqueKey] && mapConfigs[uniqueKey].showLayer && entry.id != editedPoiGuid) {
            let geojson = new GeoJSON();

            let innerFeatures = [];
            if(entry[vectorOverlayKey]) {
              let geoJsonString = null;
              try {
                geoJsonString = JSON.parse(entry[vectorOverlayKey])
              } catch(e) {}



              if (mapConfigs[uniqueKey] && mapConfigs[uniqueKey].showIcons) {
                //Transform features

                if(true) {
                  geoJsonString = evaluateJavascript(geoJsonString);
                }
              }



              if(geoJsonString != null) {
                innerFeatures = geojson.readFeatures(geoJsonString, {
                  dataProjection: "EPSG:4326",
                  featureProjection: "EPSG:3857",
                });
              }
            }

            innerFeatures.forEach((feature) => {
              feature.setProperties(entry);
              feature.setProperties({
                layerId: uniqueKey + "_" + vectorOverlayKey,
                mapLabelTemplate: config?.mapLabelTemplate,
                blobGuid: entry.id,
              });
            });

            features = features.concat(innerFeatures);
          }
        });
      }

      if (vecLayerRef != undefined) {
        vecLayerRef.current.setStyle((features, resolution) => evaluateGeometryStyle(features, resolution, styles, selectedStyles[uniqueKey], mapConfigs));
      }

      if (vecLayerRef.current) {
        vecLayerRef.current.getSource().clear();
        vecLayerRef.current.getSource().addFeatures(features);
      }
    }
  }



  //#region  Export

  function getSelectedPois() {
    return selectedPois;
  }

  function getSelectedBlobs() {
    return selectedBlobs;
  }

  function getLayerArr() {
    return layers;
  }

  function getEnabledBaseLayer() {
    return enabledBaseLayer;
  }

  function getEnabledLayers() {
    return enabledLayers;
  }

  function getSelectedStyle() {
    return selectedStyles;
  }

  function getMapConfig() {
    return mapConfigs;
  }

  function getGisStyles() {
    return styles;
  }

  function getMapPos() {
    if (mapRef.current) {
      return {
        pos: toLonLat(mapRef.current.getView().getCenter()),
        zoom: mapRef.current.getView().getZoom(),
      };
    }
    return {
      pos: [0, 0],
      zoom: 10,
    };
  }

  function getGeometryAsKml() {
    if (drawVectorLayerRef.current) {
      let vectorOverlayKey = "map";
      let source = drawVectorLayerRef.current.getSource();
      if(source) {
        vectorOverlayKey = source.get("vectorOverlayKey");
      }

      console.log("vectorOverlayKey", vectorOverlayKey, source)

      let kml = GetKMLFromFeatures(drawVectorLayerRef.current.getSource().getFeatures());
      return({vectorOverlayKey: vectorOverlayKey, data: kml});
    }
    return({column: "", data: ""});
  }

  function getGeometryAsGeoJSON() {
    if (drawVectorLayerRef.current) {
      let geojson = GetGeoJSONFromFeatures(drawVectorLayerRef.current.getSource().getFeatures());

      console.log(geojson)

      return({vectorOverlayKey: "map", data: geojson});
    }
    return({column: "", data: ""});
  }

  function changeIgnoreZoomOnGeometry(active) {
    setIgnoreZoomOnGeometry(active);
  }

  const {
    setGetSelectedPoisFunction,
    setGetSelectedBlobsFunction,
    setGetSelectedStyleFunction,
    setGetMapConfigFunction,
    setGetGisStyleFunction,
    setGetMapPosFunction,
    setGetLayerArrFunction,
    setGetEnabledBaseLayerFunction,
    setGetEnabledLayersFunction,
    setGetGeometryAsKmlFunction,
    setGetGeometryAsGeoJSONFunction,
    setChangeIgnoreZoomOnGeometry,

    mapData,

    entryToMove,
    setEntryToMove,
    posCallback
  } = useMap();
  useEffect(() => {
    setGetGisStyleFunction(getGisStyles);
    setGetSelectedPoisFunction(getSelectedPois);
    setGetSelectedBlobsFunction(getSelectedBlobs);
    setGetLayerArrFunction(getLayerArr);
    setGetEnabledBaseLayerFunction(getEnabledBaseLayer);
    setGetEnabledLayersFunction(getEnabledLayers);
    setGetSelectedStyleFunction(getSelectedStyle);
    setGetMapConfigFunction(getMapConfig);
    setGetMapPosFunction(getMapPos);
    setGetGeometryAsKmlFunction(getGeometryAsKml);
    setGetGeometryAsGeoJSONFunction(getGeometryAsGeoJSON);
    setChangeIgnoreZoomOnGeometry(changeIgnoreZoomOnGeometry);
  }, []);
  //#endregion



  //MOVE ENTRY CONTROL
  const moveModifyRef = useRef();
  const moveDrawLayer = useRef();

  function drawEntryMoveLayer(entry) {
    if (moveDrawLayer.current) {
      moveDrawLayer.current.getSource().clear();
    }

    if(entry == null || selectedCfg == null) return;

    let iconSrc = selectedCfg?.tableIcon;
    let tableIconBgColor = selectedCfg?.tableIconBgColor;

    if (moveDrawLayer.current == null) {
      const source = new VectorSource({ wrapX: false });
      const lassoVector = new VectorLayer({
        id: "moveLayer",
        source: source,
        zIndex: 1000
      });
      moveDrawLayer.current = lassoVector;
      if (mapRef.current != null) {
        mapRef.current.addLayer(moveDrawLayer.current);
      }
    }

    if(moveDrawLayer.current) {
      moveDrawLayer.current.setStyle([
        new Style({
          image: new Icon({
            anchor: [0.5, 1],
            src: mapBgIcon,
            height: 32,
            color: tableIconBgColor
          }),
        }),
        new Style({
          image: new Icon({
            anchor: [0.5, 1],
            src: iconSrc,
            height: 24,
            displacement: [0, 7],
          })
        }),
        new Style({
          image: new CircleStyle({
            radius: 4 * 2,
            fill: new Fill({
              color: "#fff",
            }),
          }),
          zIndex: Infinity,
        }),
        new Style({
          image: new CircleStyle({
            radius: 3 * 2,
            fill: new Fill({
              color: "#090",
            }),
          }),
          zIndex: Infinity,
        })
      ]);
    }
    
    let feature = new Feature({
      geometry: new Point([
        !isNaN(entry.location?.long) ? entry.location?.long : 0,
        !isNaN(entry.location?.lat) ? entry.location?.lat : 0,
      ]).transform("EPSG:4326", "EPSG:3857"),
      guid: entry.id,
    });

    moveModifyRef.current = new Modify({
      source: moveDrawLayer.current.getSource(),
      style: [
        new Style({
          image: new CircleStyle({
            radius: 4 * 2,
            fill: new Fill({
              color: "#fff",
            }),
          }),
          zIndex: Infinity,
        }),
        new Style({
          image: new CircleStyle({
            radius: 2.5 * 2,
            fill: new Fill({
              color: "#090",
            }),
          }),
          zIndex: Infinity,
        })
      ],
    });

    moveModifyRef.current.on("modifyend", (e) => {
      let feature = e.features?.getArray()[0];
      if(feature != null) {
        let coords = toLonLat(feature.getGeometry().getCoordinates());
        mapData?.posCallback(coords[1], coords[0]);
      }
    });

    if (moveDrawLayer.current) {
      moveDrawLayer.current.getSource().addFeatures([feature]);
    }

    if(mapRef.current != null) {
      var animationOptions = {
        duration: 750,
        padding: [100, 100, 100, 100],
        maxZoom: 16,
        easing: easeOut
      };
      mapRef.current.getView().animate({
        center: fromLonLat([
          !isNaN(entry.location?.long) ? entry.location?.long : 0,
          !isNaN(entry.location?.lat) ? entry.location?.lat : 0
        ], "EPSG:3857")
      });
    }

    if(mapRef.current) {
      mapRef.current.addInteraction(moveModifyRef.current);
    }
  }

  useEffect(() => {
    if(entryToMove) {
      console.log("ACTIVATE")
      let entry = dataEntries[selectedCfg?.uniqueKey].find((i) => i.id == entryToMove);
      drawEntryMoveLayer(entry);
    } else {
      console.log("DEACTIVATE")
      drawEntryMoveLayer(null);
    }
  }, [entryToMove]);

  //DRAW CONTROLS
  const [controlsOpen, setControlsOpen] = useState(false);
  const [isInital, setIsInital] = useState(true);
  const [selectedTool, setSelectedTool] = useState(null);
  const [snapping, setSnapping] = useState(false);
  const [ignoreZoomOnGeometry, setIgnoreZoomOnGeometry] = useState(false);
  const [selectedFeature, setSelectedFeature] = useState([]);
  const drawVectorLayerRef = useRef();
  const selectRef = useRef();
  const snapRef = useRef();
  const drawRef = useRef();
  const modifyRef = useRef();

  const handleClick = useCallback(() => {
    setSelectedTool(null);
    setControlsOpen(!controlsOpen);
  }, [controlsOpen, selectedTool]);

  const selectTool = useCallback((tool) => {
    setSelectedTool(tool != selectedTool ? tool : null);
  }, [selectedTool])

  const onSnappingChanged = useCallback((value) => {
    setSnapping(value)
  }, []);

  const deleteGeometry = useCallback((feature) => {
    if (drawVectorLayerRef.current) {
      drawVectorLayerRef.current.getSource().removeFeature(feature);
      setSelectedFeature([]);
    }
  }, [drawVectorLayerRef.current, selectedFeature])

  const saveKml = useCallback(() => {
    setSelectedTool("SaveKml");
    setControlsOpen(false);
  }, [])

  function featureStyle(feature, resolution) {
    var geometryType = feature.getGeometry().getType();
    if (geometryType === "Point") {
      return new Style({
        text: new Text({
          font: "16px Calibri,sans-serif",
          fill: new Fill({
            color: "#000",
          }),
          stroke: new Stroke({
            color: "#fff",
            width: 3,
          }),
          offsetY: -10,
          textBaseline: "top",
          declutterMode: "declutter",
          text: feature.get("name"),
        }),
        image: new Icon({
          anchor: [0.5, 1],
          src: kmlMarker,
          height: 36,
          color: feature.get("stroke"),
        }),
        zIndex: Infinity,
      });
    } else if (geometryType === "LineString") {
      return [
        new Style({
          text: new Text({
            font: "16px Calibri,sans-serif",
            fill: new Fill({
              color: "#000",
            }),
            stroke: new Stroke({
              color: "#fff",
              width: 3,
            }),
            offsetY: -10,
            textBaseline: "top",
            declutterMode: "declutter",
            text: feature.get("name"),
          }),
          stroke: feature.get("stroke") && feature.get("stroke-width") ? new Stroke({
            color: feature.get("stroke"),
            width: feature.get("stroke-width"),
          }) : null,
        }),
        new Style({
          image: new CircleStyle({
            radius: 16 / 2,
            fill: new Fill({
              color: "#ffffff",
            }),
          }),
          geometry: function (feature) {
            const coordinates = feature.getGeometry().getCoordinates();
            return new MultiPoint(coordinates);
          },
        })
      ];
    } else if (geometryType === "Polygon" || geometryType === "MultiPolygon") {
      return [
        new Style({
          text: new Text({
            font: "16px Calibri,sans-serif",
            fill: new Fill({
              color: "#000",
            }),
            stroke: new Stroke({
              color: "#fff",
              width: 3,
            }),
            offsetY: -10,
            textBaseline: "top",
            declutterMode: "declutter",
            text: feature.get("name"),
          }),
          fill: feature.get("fill") ? new Fill({
            color: feature.get("fill"),
          }) : null,
          stroke: feature.get("stroke") && feature.get("stroke-width") ? new Stroke({
            color: feature.get("stroke"),
            width: feature.get("stroke-width"),
          }) : null,
        }),
        new Style({
          image: new CircleStyle({
            radius: 16 / 2,
            fill: new Fill({
              color: "#ffffff",
            }),
          }),
          geometry: function (feature) {
            const coordinates = feature.getGeometry().getCoordinates()[0];
            return new MultiPoint(coordinates);
          },
        })
      ]
    }
  }

  function selectedItemStyle(feature, resolution) {
    var geometryType = feature.getGeometry().getType();
    if (geometryType === "Point") {
      return [
        new Style({
          text: new Text({
            font: "16px Calibri,sans-serif",
            fill: new Fill({
              color: "#000",
            }),
            stroke: new Stroke({
              color: "#fff",
              width: 3,
            }),
            offsetY: -10,
            textBaseline: "top",
            declutterMode: "declutter",
            text: feature.get("name"),
          }),
          image: new Icon({
            anchor: [0.5, 1],
            src: kmlMarker,
            height: 36,
            color: feature.get("stroke"),
          }),
          zIndex: Infinity,
        }),
        new Style({
          text: new Text({
            font: "16px Calibri,sans-serif",
            fill: new Fill({
              color: "#000",
            }),
            stroke: new Stroke({
              color: "#fff",
              width: 3,
            }),
            offsetY: -10,
            textBaseline: "top",
            declutterMode: "declutter",
            text: feature.get("name"),
          }),
          image: new CircleStyle({
            radius: feature.get("stroke-width") ? feature.get("stroke-width") : null,
            fill: feature.get("fill") ? new Fill({
              color: feature.get("fill"),
            }) : null,
            stroke: feature.get("stroke") && feature.get("stroke-width") ? new Stroke({
              color: feature.get("stroke"),
              width: feature.get("stroke-width"),
            }) : null,
          }),
          zIndex: Infinity,
        }),
    ];
    } else if (geometryType === "LineString") {
      return [
        new Style({
          text: new Text({
            font: "16px Calibri,sans-serif",
            fill: new Fill({
              color: "#000",
            }),
            stroke: new Stroke({
              color: "#fff",
              width: 3,
            }),
            offsetY: -10,
            textBaseline: "top",
            declutterMode: "declutter",
            text: feature.get("name"),
          }),
          stroke: feature.get("stroke-width") ? new Stroke({
            color: "#fff",
            width: (feature.get("stroke-width")) + 4,
          }) : null,
        }),
        new Style({
          stroke: feature.get("stroke") && feature.get("stroke-width") ? new Stroke({
            color: feature.get("stroke"),
            width: feature.get("stroke-width"),
          }) : null,
        }),
      ];
    } else if (geometryType === "Polygon" || geometryType === "MultiPolygon") {
      return [
        new Style({
          text: new Text({
            font: "16px Calibri,sans-serif",
            fill: new Fill({
              color: "#000",
            }),
            stroke: new Stroke({
              color: "#fff",
              width: 3,
            }),
            offsetY: -10,
            textBaseline: "top",
            declutterMode: "declutter",
            text: feature.get("name"),
          }),
          stroke: feature.get("stroke-width") ? new Stroke({
            color: "#fff",
            width: (feature.get("stroke-width")) + 4,
          }) : null,
        }),
        new Style({
          fill: feature.get("fill") ? new Fill({
            color: feature.get("fill"),
          }) : null,
          stroke: feature.get("stroke") && feature.get("stroke-width") ? new Stroke({
            color: feature.get("stroke"),
            width: feature.get("stroke-width"),
          }) : null,
        })
      ];
    }
  }

  function modifyStyle(feature, resolution) {
    var geometryType = feature.getGeometry().getType();
    if (geometryType === "Point") {
      return new Style({
        image: new Icon({
          anchor: [0.5, 1],
          src: kmlMarker,
          height: 36,
          color: "#009900",
        }),
        zIndex: Infinity,
      });
    } else if (geometryType === "LineString") {
      return [
        new Style({
          stroke: new Stroke({
            color: "#fff",
            width: 3 + 2,
          }),
        }),
        new Style({
          stroke: new Stroke({
            color: "#009900",
            width: 3,
          }),
        })
      ];
    } else if (geometryType === "Polygon" || geometryType === "MultiPolygon") {
      return [
        new Style({
          fill: new Fill({
            color: [255, 255, 255, 0.5],
          }),
        }),
        new Style({
          image: new CircleStyle({
            radius: 16 / 2,
            fill: new Fill({
              color: "#ffffff",
            }),
          }),
          geometry: function (feature) {
            const coordinates = feature.getGeometry().getCoordinates()[0];
            return new MultiPoint(coordinates);
          },
        })
      ]
    }
  }

  function addInteraction(type, inital) {
    if (drawRef.current != null) {
      mapRef.current.removeInteraction(drawRef.current);
      mapRef.current.removeInteraction(modifyRef.current);
    }

    if (drawVectorLayerRef.current == null) {
      const source = new VectorSource({ wrapX: false });
      const lassoVector = new VectorLayer({
        id: "drawLayer",
        source: source,
        style: featureStyle,
        zIndex: 999
      });
      drawVectorLayerRef.current = lassoVector;
      if (mapRef.current != null) {
        mapRef.current.addLayer(lassoVector);
      }
    }

    //LOAD Features from geoJSON
    let entry = selectedPoisByUniqueId && selectedPoisByUniqueId.length > 0 ? selectedPoisByUniqueId[0] : null;
    if(entry && inital) {
      let geojson = new GeoJSON();

      let vectorOverlayKey = "map";
      if(selectedCfg) {
        let vectorOverlay = selectedCfg.columns.find((row) => row.fieldType == "Map");
        if(vectorOverlay != null && vectorOverlay.columnKey != null) vectorOverlayKey = vectorOverlay.columnKey;
      }

      let innerFeatures = [];
      if(entry[vectorOverlayKey]) {
        let geoJsonString = {};
        try {
          geoJsonString = JSON.parse(entry[vectorOverlayKey])
        } catch(e) {}
        
        if(geoJsonString != null) {
          innerFeatures = geojson.readFeatures(geoJsonString, {
            dataProjection: "EPSG:4326",
            featureProjection: "EPSG:3857",
          });
        }
      }

      innerFeatures.map((feature, idx) => {
        let color;
        let lineWidth;
        let fillColor;
        let name = feature.get("name");

        let styleFunction = feature.getStyle();

        if(styleFunction == null) {
          innerFeatures[idx].set("stroke", feature.get("stroke"));
          innerFeatures[idx].set("stroke-width", feature.get("stroke-width"));
          innerFeatures[idx].set("fill", feature.get("fill"));
          innerFeatures[idx].set("name", feature.get("name"));
          feature.setStyle(featureStyle);
          return;
        }

        let style = styleFunction(feature, mapRef.current.getView().getResolution());
        if(style == null || style.length == 0) return;

        if(style.length > 0) {
          style = style[0];
        }

        if(style.getStroke()) {
          let colorArr = style.getStroke().getColor();
          if(colorArr.length == 4) {
            color = rgbToHexA(colorArr);
          }
        }

        if(style.getStroke()) {
          let width = style.getStroke().getWidth();
          if(width != null) {
            lineWidth = width != 0 ? width : 2;
          }
        }

        if(style.getFill()) {
          let colorArr = style.getFill().getColor();
          if(colorArr.length == 4) {
            fillColor = rgbToHexA(colorArr);
          }
        }

        if(style.getText()) {
          let name = style.getText().getText();
          if(name != null) {
            name = name;
          }
        }

        innerFeatures[idx].set("stroke", color);
        innerFeatures[idx].set("stroke-width", lineWidth);
        innerFeatures[idx].set("fill", fillColor);
        innerFeatures[idx].set("name", name);

        innerFeatures[idx].setStyle(featureStyle);
      });

      if(drawVectorLayerRef.current) {
        drawVectorLayerRef.current.getSource().set("poiGuid", entry.id);
        drawVectorLayerRef.current.getSource().clear();
        drawVectorLayerRef.current.getSource().addFeatures(innerFeatures);
      }

      if (drawVectorLayerRef.current) {
        drawVectorLayerRef.current.getSource().set("vectorOverlayKey", vectorOverlayKey);
      }
    }

    modifyRef.current = new Modify({
      source: drawVectorLayerRef.current.getSource(),
      deleteCondition: selectedTool == "DeleteVertices" ? always : never,
      style: modifyStyle,
    });

    let geometryFunction = null;
    if(type == "Box") {
      type = 'Circle';
      geometryFunction = createBox();
    }

    drawRef.current = new Draw({
      source: drawVectorLayerRef.current.getSource(),
      type: type == null || type == "EditGeometry" || type == "Square" || (type == "SaveKml" || type == "DeleteVertices") ? "Point" : type,
      style: modifyStyle,
      geometryFunction: geometryFunction,
    });

    drawRef.current.on("drawend", function (evt) {
      var feature = evt.feature;
      feature.setId(uuidv4());

      if(feature.getGeometry().getType() == "LineString") {
        feature.setProperties({
          "name": "",
          "stroke": "#FFFFFF",
          "stroke-width": 2
        });
      } else {
        feature.setProperties({
          "name": "",
          "stroke": "#FFFFFF",
          "stroke-width": 2,
          "fill": "#FFFFFF44",
        });
      }

      //Buffer test
      /*let skidroad = 2;
      let crane = 10;

      const format = new GeoJSON();
      var line = lineString(feature.getGeometry().transform('EPSG:3857', 'EPSG:4326').getCoordinates());
      feature.getGeometry().transform('EPSG:4326', 'EPSG:3857');

      var skidroadBuffered = buffer(line, skidroad * 0.001, { units: "kilometers" });
      var craneBuffered = buffer(line, crane * 0.001, { units: "kilometers" });

      const skidroadBufferFeature = format.readFeature(skidroadBuffered);
      skidroadBufferFeature.setProperties({
        name: "",
        _color: "#ffdd30",
        _lineWidth: 2,
        _fillColor: "#ffdd3044",
      });
      skidroadBufferFeature.getGeometry().transform('EPSG:4326', 'EPSG:3857');

      const craneBufferFeature = format.readFeature(craneBuffered);
      craneBufferFeature.setProperties({
        name: "",
        _color: "#FFFFFF",
        _lineWidth: 2,
        _fillColor: "#FFFFFF44",
      });
      craneBufferFeature.getGeometry().transform('EPSG:4326', 'EPSG:3857');

      drawVectorLayerRef.current.getSource().addFeature(skidroadBufferFeature);
      drawVectorLayerRef.current.getSource().addFeature(craneBufferFeature);*/
    });

    if (mapRef.current != null && type !== null && (type !== "EditGeometry" && type !== "DeleteVertices"))
      mapRef.current.addInteraction(drawRef.current);
    if (mapRef.current != null && type === "EditGeometry" || type === "DeleteVertices")
      mapRef.current.addInteraction(modifyRef.current);
  }

  function removeInteraction(clearLayer) {
    if (drawVectorLayerRef.current != null && clearLayer) {
      drawVectorLayerRef.current.getSource().clear();
      drawVectorLayerRef.current = null;
    }
    if (mapRef.current != null) {
      mapRef.current.removeInteraction(drawRef.current);
      mapRef.current.removeInteraction(modifyRef.current);
    }
  }

  function handleDrawControls() {
    if (props.editGeometry) {
      addInteraction(selectedTool, isInital);
      setIsInital(false);

      if (selectedTool == null && mapRef.current) {
        selectRef.current = new Select({
          condition: click,
          style: featureStyle,
          layers: [drawVectorLayerRef.current, vecLayerRef.current]
        });
        mapRef.current.addInteraction(selectRef.current);
        selectRef.current.on("select", (e) => {
          let selectedFeatures = e.target.getFeatures().getArray();
          setSelectedFeature([...selectedFeatures]);
          if(drawVectorLayerRef.current) {
            let features = drawVectorLayerRef.current.getSource().getFeatures();
            if(features != null) {
              features.map((feature) => {
                feature.setStyle(selectedFeatures.includes(feature) ? selectedItemStyle : featureStyle);
              });
            }
          }
        });
      } else {
        if (selectRef.current) {
          mapRef.current.removeInteraction(selectRef.current);
          setSelectedFeature([]);
        }
      }

      if (snapping && mapRef.current) {
        snapRef.current = new Snap({
          source: drawVectorLayerRef.current.getSource(),
        });
        mapRef.current.addInteraction(snapRef.current);
      } else {
        if (snapRef.current) {
          mapRef.current.removeInteraction(snapRef.current);
        }
      }

    } else {
      if (drawVectorLayerRef.current && selectedTool == "SaveKml") {
        let geoJson = GetGeoJSONFromFeatures(drawVectorLayerRef.current.getSource().getFeatures());
        props.onGeometryChanged(geoJson);
      }
      removeInteraction(true);
      if (selectRef.current) {
        mapRef.current.removeInteraction(selectRef.current);
        setSelectedFeature([]);
      }

      if (snapRef.current) {
        mapRef.current.removeInteraction(snapRef.current);
      }

      setControlsOpen(false);
      setIsInital(true);
    }
  }

  function GetKMLFromFeatures(features) {
    features.map((feature) => {
      feature.setStyle(featureStyle);
    });

    var format = new KML();
    var kml = format.writeFeatures(features, {
      featureProjection: "EPSG:3857",
      extractStyles: true,
    });
    return kml;
  }

  function GetGeoJSONFromFeatures(features) {
    features.map((feature) => {
      feature.setStyle(featureStyle);
    });

    var format = new GeoJSON();
    var geojson = format.writeFeaturesObject(features, {
      featureProjection: "EPSG:3857",
      extractStyles: true,
    });
    return geojson;
  }

  useEffect(() => {
    handleDrawControls();
  }, [selectedTool, controlsOpen, snapping, props.appView, props.editGeometry]);



  //Change Layer
  function handleMapLayer() {
    if (mapRef.current) {
      //baseLayer
      let selectedBaseLayer = layers ? layers.find((layer) => layer.layerKey == props.enabledBaseLayer) : null;
      if (selectedBaseLayer) {
        let source = null;
        if (selectedBaseLayer.layerKey == "osm") {
          source = new OSM();
        } else {
          switch (
            selectedBaseLayer.layerType ? selectedBaseLayer.layerType : ""
          ) {
            case "wms":
              source = new TileWMS({
                url: selectedBaseLayer.layerUrl,
                params: selectedBaseLayer.layerParams
              });
              break;
            default:
              source = new XYZ({
                url: selectedBaseLayer.layerUrl ? selectedBaseLayer.layerUrl.replaceAll("$", "") : "",
                crossOrigin: "anonymous",
              });
              break;
          }
        }
        source.set("layerKey", selectedBaseLayer.layerKey);
        source.set("wms", true);
        let baseLayer = mapRef.current
          .getLayers()
          .getArray()
          .find((layer) => layer.get("type") == "base_layer");
        if (baseLayer) {
          let currentSource = baseLayer.getSource();
          if (
            currentSource &&
            currentSource.get("layerKey") != source.get("layerKey")
          ) {
            baseLayer.setSource(source);
          }
        }
      }
      //Overlay
      let newSelectedLayers = [];
      enabledLayers.map((enabledlayer, idx) => {
        let layer = layers ? layers.find((layer) => layer.layerKey == enabledlayer) : null;
        if (layer) {
          let source = null;
          if (layer.layerKey == "osm") {
            source = new OSM();
          } else {
            switch (layer.layerType ? layer.layerType : "") {
              case "wms":
                source = new TileWMS({
                  url: layer.layerUrl,
                  params: layer.layerParams,
                  crossOrigin: "anonymous"
                });
                break;
              default:
                source = new XYZ({
                  url: layer.layerUrl.replaceAll("$", ""),
                  crossOrigin: "anonymous",
                });
                break;
            }
          }
          source.set("layerKey", enabledlayer);
          source.set("wms", true);
          let newLayer = new TileLayer({
            type: "overlay_layer",
            source: source,
            zIndex: idx + 1,
          });
          newSelectedLayers.push(newLayer);
        }
      });

      let currentLayers = Object(mapRef.current.getLayers().getArray());

      //Remove old layers
      let layersToRemove = [];
      currentLayers.map((currentLayer) => {
        let foundLayer = newSelectedLayers ? newSelectedLayers.find((layer) => layer.getSource().get("layerKey") == currentLayer.getSource().get("layerKey")) : null;
        if (!foundLayer && currentLayer.get("type") == "overlay_layer") {
          layersToRemove.push(currentLayer);
        }
      });

      //Remove all Layers
      layersToRemove.map((layer) => {
        mapRef.current.removeLayer(layer);
      })

      //Add new layers
      newSelectedLayers.map((newLayer) => {
        let foundLayer = currentLayers ? currentLayers .find((layer) => layer.getSource().get("layerKey") == newLayer.getSource().get("layerKey")): null;
        if (!foundLayer) {
          mapRef.current.addLayer(newLayer);
        }
      });
    }
  }

  useEffect(() => {
    handleMapLayer();
  }, [enabledBaseLayer, enabledLayers]);


  
  //Autorefreshs
  function initMap() {
    mapElement.current.innerHTML = "";

    baseSrc.set("layerKey", "google_luftbild");
    var baseLayer = new TileLayer({
      type: "base_layer",
      source: baseSrc,
    });
    baseLayerRef.current = baseLayer;

    const overlay = new Overlay({
      element: popupDivRef.current,
      autoPan: {
        animation: {
          duration: 250,
        },
      },
    });
    popupRef.current = overlay;





    //VectorTile Test
    //VectorTile Test
    //VectorTile Test

    const replacer = function (key, value) {
      if (!value || !value.geometry) {
        return value;
      }
    
      let type;
      const rawType = value.type;
      let geometry = value.geometry;
      if (rawType === 1) {
        type = 'MultiPoint';
        if (geometry.length == 1) {
          type = 'Point';
          geometry = geometry[0];
        }
      } else if (rawType === 2) {
        type = 'MultiLineString';
        if (geometry.length == 1) {
          type = 'LineString';
          geometry = geometry[0];
        }
      } else if (rawType === 3) {
        type = 'Polygon';
        if (geometry.length > 1) {
          type = 'MultiPolygon';
          geometry = [geometry];
        }
      }
    
      return {
        'type': 'Feature',
        'geometry': {
          'type': type,
          'coordinates': geometry,
        },
        'properties': value.tags,
      };
    };

    const vectorTileTestLayer = new VectorTileLayer({
      style: {
        'stroke-color': ['string', ['get', 'COLOR'], 'black'],
        'fill-color': ['string', ['get', 'COLOR'], '#00000022'],
      },
    });
    
    const tileIndex = geojsonvt(geojsonObject, {
      extent: 4096,
      debug: 1,
      maxZoom: 22,
      indexMaxZoom: 22
    });

    const format = new GeoJSON({
      // Data returned from geojson-vt is in tile pixel units
      dataProjection: new Projection({
        code: 'TILE_PIXELS',
        units: 'tile-pixels',
        extent: [0, 0, 4096, 4096],
      }),
    });

    const vectorSource = new VectorTileSource({
      tileUrlFunction: function (tileCoord) {
        // Use the tile coordinate as a pseudo URL for caching purposes
        return JSON.stringify(tileCoord);
      },
      tileLoadFunction: function (tile, url) {
        const tileCoord = JSON.parse(url);
        const data = tileIndex.getTile(
          tileCoord[0],
          tileCoord[1],
          tileCoord[2],
        );
        const geojson = JSON.stringify(
          {
            type: 'FeatureCollection',
            features: data ? data.features : [],
          },
          replacer,
        );
        const features = format.readFeatures(geojson, {
          extent: vectorSource.getTileGrid().getTileCoordExtent(tileCoord),
          featureProjection: map.getView().getProjection(),
        });

        tile.setFeatures(features);
      },
    });

    vectorTileTestLayer.setSource(vectorSource);
    
    //VectorTile Test
    //VectorTile Test
    //VectorTile Test




    let initMap = new Map({
      controls: defaultControls(),
      target: mapElement.current,
      layers: [baseLayer],
      overlays: [overlay],
      view: new View({
        center: fromLonLat(
          props.userSettings != null && props.userSettings.mapPos != null
            ? props.userSettings.mapPos.pos
            : [10.470215, 50.750766]
        ),
        zoom:
          props.userSettings != null && props.userSettings.mapPos != null
            ? props.userSettings.mapPos.zoom
            : 7,
        minZoom: 5,
        maxZoom: 21,
      }),
    });


    //CHANGE CURSOR ON MAPVIEW
    initMap.on("pointermove", function (evt) {
      if (evt.dragging) {
        return;
      }
      var hit = false;
      hit = this.forEachFeatureAtPixel(
        evt.pixel,
        function (feature, layer) {
          if(selectedTool == null || layer == drawVectorLayerRef.current)
            return true;
        }
      );
      if(!hit) {
        let mapLayers = initMap.getLayers().getArray();
        mapLayers.map((mapLayer) => {
          if(!hit && mapLayer.get("type") == "overlay_layer") {
            const data = mapLayer.getData(evt.pixel);
            hit = data && data[3] > 0;
          }
        })
      }
      if (hit) {
        this.getTargetElement().style.cursor = "pointer";
      } else {
        this.getTargetElement().style.cursor = "";
      }
    });

    selectClickRef.current = new Select({
      condition: click,
      filter: (feature, layer) => {
        return layer != null ? layer.get("id") != "drawLayer" : false;
      },
    });
    initMap.addInteraction(selectClickRef.current);
    selectClickRef.current.on('select', function (e) {
      let features = e.target.getFeatures().getArray();
      let objArr = [];
      let pos;

      if(features.length > 0) {
        features.map((feature) => {
          let properties;
          let cfg;
          if(feature.get("features")) {
            properties = feature.get("features")[0].getProperties();
            cfg = cfgs.find((config) => config.uniqueKey == properties["layerId"]);
            pos = features[0].getGeometry().getCoordinates();
          } else {
            properties = feature.getProperties();
            let extend = features[0].getGeometry().getExtent();
            if(features[0].getGeometry().getType() == "LineString") {
              let coords = features[0].getGeometry().getCoordinates();
              let middleCoords = coords[parseInt(coords.length/2)];
              pos = middleCoords;
            } else {
              pos = getCenter(extend);
            }
          }
          let newObj = {};

          if(!feature.get("features")) {
            let length = getLengthString(feature.getGeometry());
            if(getArea(feature.getGeometry()) > 0) {
              newObj["Umfang"] = length;
              newObj["Fläche"] = getAreaSizeString(feature.getGeometry());
            } else {
              newObj["Länge"] = length;
            }
          }

          if(properties) {
            Object.entries(properties).forEach(([key, value]) => {
              let label = cfg != null ? cfg.columns?.find(item=> item.key === key)?.columnName : key;
              if(label != undefined && key != "location") {
                newObj[label] = value;
              }
            });
          }

          if(properties.geometry) {
            let coords = toLonLat(properties.geometry.getCoordinates());
            newObj["Latitude"] = coords[1]?.toFixed(6)
            newObj["Longitude"] = coords[0]?.toFixed(6);
          }

          objArr.push(newObj);
        })

        setPopupData({type: "select", data: objArr});
        popupRef.current.setPosition(pos);
      } else {
        setPopupData({type: null, data: []});
        popupRef.current.setPosition(null);
      }
    });

    initMap.on("singleclick", function (evt) {
      let mapLayers = initMap.getLayers().getArray();

      let features = initMap.getFeaturesAtPixel(evt.pixel, {hitTolerance: 20});
      if(features.length > 0) return;

      mapLayers.map((mapLayer) => {
        if(mapLayer.getSource().get("wms") == null) return;
        if(mapLayer.get("type") == "overlay_layer") {
          const viewResolution = initMap.getView().getResolution();

          console.log(mapLayer.getSource() instanceof TileWMS);
          if(!(mapLayer.getSource() instanceof TileWMS)) return;


          let url = mapLayer.getSource().getFeatureInfoUrl(evt.coordinate, viewResolution, 'EPSG:3857', {'INFO_FORMAT': 'text/xml'});
          url = url.replace("layers", "query_layers");
          if (url) {
            fetch(url)
              .then((response) => response.text())
              .then((html) => {
                const myJson = convertXML(html);
                if(myJson?.FeatureInfoResponse?.children?.length > 0) {
                  setPopupData({type: "wms", data: [myJson?.FeatureInfoResponse?.children[0]?.FIELDS]});
                  popupRef.current.setPosition(evt.coordinate);
                } else {
                  setPopupData({type: null, data: []});
                  popupRef.current.setPosition(null);
                }
              });
          }
        }
      });
    });

    initMap.on("moveend", function(e) {
      if (moveEndTimeoutRef.current) {
        clearTimeout(moveEndTimeoutRef.current);
      }
      moveEndTimeoutRef.current = setTimeout(() => {
        const map = e.map;
        const pos = toLonLat(map.getView().getCenter());
        const zoom = map.getView().getZoom();
        setMapPos({ pos: pos, zoom: zoom });
      }, 1000);
    });

    mapRef.current = initMap;
    setMap(initMap);

    if(props.onPositionChanged) {
      props.onPositionChanged([
        props.poiPosition ? props.poiPosition.lon : 10.470215,
        props.poiPosition ? props.poiPosition.lat : 50.750766,
      ])
    }
  }

  useEffect(() => {

    console.log("RERENDER MAP")

    drawPoiGeometries();
    drawPoisPosition();

    if(!ignoreZoomOnGeometry) {
      var poiExtent;
      var geomExtent;
      if(mapRef.current) {
        let layers = mapRef.current.getAllLayers();
        layers.map((layer) => {
          if(layer.get("layerId")) {
            //POI
            if(layer.get("zIndex") == 999 && layer.getSource().getExtent() != undefined) {
              if(poiExtent == undefined) poiExtent = createEmpty();
              extend(poiExtent, layer.getSource().getExtent())
            }
            //Geoms
            if(layer.get("zIndex") == 998 && layer.getSource().getExtent() != undefined) {
              if(geomExtent == undefined) geomExtent = createEmpty();
              extend(geomExtent, layer.getSource().getExtent())
            }
          }
        });
      }

      let hasGeometries = geomExtent != null;
      let hasPoiIcons = poiExtent != null;
      let hasEditableGeometries = drawVectorLayerRef.current != null && drawVectorLayerRef.current.getSource().getFeatures().length > 0;

      if(hasPoiIcons && hasGeometries) {
        var extent = createEmpty();
        extend(extent, poiExtent);
        extend(extent, geomExtent);

        if(mapRef.current != null && selectedPois != null) {
          var animationOptions = {
            duration: 750,
            padding: [100, 100, 100, 100],
            maxZoom: 16,
            easing: easeOut
          };
          if(extent != null && extent[0] != Infinity) mapRef.current.getView().fit(extent, animationOptions);
        }
      }

      if(hasPoiIcons && !hasGeometries) {
        if(mapRef.current != null && selectedPois != null) {
          var animationOptions = {
            duration: 750,
            padding: [100, 100, 100, 100],
            maxZoom: 16,
            easing: easeOut
          };
          if(poiExtent != null && poiExtent[0] != Infinity) mapRef.current.getView().fit(poiExtent, animationOptions);
        }
      }

      if(!hasPoiIcons && hasGeometries) {
        if (mapRef.current != null && selectedPois != null) {
          var animationOptions = {
            duration: 750,
            padding: [100, 100, 100, 100],
            maxZoom: 16,
            easing: easeOut
          };
          if(geomExtent != null && geomExtent[0] != Infinity) mapRef.current.getView().fit(geomExtent, animationOptions);
        }
      }

      if(hasEditableGeometries) {
        if (mapRef.current != null && selectedPois != null) {
          var animationOptions = {
            duration: 750,
            padding: [100, 100, 100, 100],
            maxZoom: 20,
            easing: easeOut
          };
          let extent = drawVectorLayerRef.current.getSource().getExtent();
          if(extent != null && extent[0] != Infinity) mapRef.current.getView().fit(extent, animationOptions);
        }
      }
    }
  }, [cfgs, selectedPois, selectedBlobs, selectedStyles, mapConfigs, props.editGeometry]);

  useEffect(() => {
    drawPoiGeometries();
  }, [drawRef.current]);

  useEffect(() => {
    props.onMapMoved(mapPos);
  }, [mapPos]);

  useEffect(() => {
    initMap();
    handleMapLayer();
  }, []);

  return (
    <>
      <div
        ref={mapElement}
        className="basicmap"
        style={{
          height: "100%",
          width: "100%",
          transition: "width .35s ease",
        }}
      ></div>
      <MeasureControls
        tableSize={props.height}
        mapRef={mapRef}
        onToggleActive={(active) => selectClickRef.current.setActive(active)}
      />
      <LayerSelection
        tableSize={props.height}
      />
      <SearchControls
        appViewSize={props.width}
        drawControlsOpen={props.editGeometry}
        mapRef={mapRef}
      />
      <FollowLocationControl
        appViewSize={props.width}
        drawControlsOpen={props.editGeometry}
        mapRef={mapRef}
      />
      <DrawControls
        controlsOpen={true}
        visible={props.editGeometry}
        selectedTool={selectedTool}
        snapping={snapping}
        onSnappingChanged={onSnappingChanged}
        selectedFeatures={selectedFeature}
        selectTool={selectTool}
        handleClick={handleClick}
        saveKml={saveKml}
        deleteGeometry={deleteGeometry}
        appViewSize={props.width}
        enabled={true}
      />
      <div ref={popupDivRef} className="ol-popup">
        <div className="ol-popup-inner">
          <div className="ol-popup-header">
            <Typography fontWeight={600} style={{ alignContent: "center", margin: "auto", userSelect: "none", color: theme.palette.text.primary}}>{popupData.type == "wms" ? "Details (Fremddaten)" : "Details"}</Typography>
          </div>
          <IconButton size="small" style={{position: "absolute", top: "3px", right: "5px", visibility: popupData.type == "wms" ? "visible" : "hidden"}} onClick={() => {
            setPopupData({type: "", data: []});
            popupRef.current.setPosition(null);
          }}><Close/></IconButton>
          <Divider />
          <div id="popup-content" className="ol-popup-content">
            {popupData.data.length > 0 ?
              <TableContainer>
                <Table size="small" aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ fontWeight: "bold", userSelect: "none" }}>Name</TableCell>
                      <TableCell align="left" style={{fontWeight: "bold", userSelect: "none"}}>Wert</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {popupData.data.map((item) => (
                      Object.entries(item).map((row) => (
                        <TableRow key={row} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell style={{userSelect: "none", overflow: "hidden", width: "50%" }} align="left">{row[0]}</TableCell>
                          <TableCell style={{userSelect: "none", width: "50%"}} align="left">{ (typeof row[1]) == "object" ? JSON.stringify(row[1]) : row[1]}</TableCell>
                        </TableRow>
                      ))
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            : null}
          </div>
        </div>
      </div>
    </>
  );
}

export default BasicMap;