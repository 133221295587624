import * as signalR from "@microsoft/signalr"; 
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useAuth } from "./AuthProvider";
import { ADMIN_URL, GATEWAY_URL } from "../js/defines";
 
//singalR REALTILE HUB

const SignalRContext = createContext();

export default function SignalRProvider({userState, bearerToken, children}) {

  const [adminConn, setAdminConn] = useState(null);
  const [companyConn, setCompanyConn] = useState(null);
  const [notifications, setNotifications] = useState([]);

  let BASE_URL = useMemo(() => {
    if(userState?.userState?.companyKey) {
      return GATEWAY_URL + "/" + userState?.userState?.companyKey;
    } else {
      return GATEWAY_URL + ADMIN_URL;
    }
  }, [userState]);


  let lastNotification = useMemo(() => {
    return notifications.length > 0 ? notifications[notifications.length - 1] : null;
  }, [notifications]);


  //Init
  useEffect(() => {
    //Admin
    const newAdminConn = new signalR.HubConnectionBuilder()
    .withUrl(GATEWAY_URL + ADMIN_URL + "/realtimeHub", {
      accessTokenFactory: () => bearerToken,
    })
    .withAutomaticReconnect()
    .build();
    setAdminConn(newAdminConn);

    //Company
    const newConnection = new signalR.HubConnectionBuilder()
    .withUrl(BASE_URL + "/realtimeHub", {
      accessTokenFactory: () => bearerToken,
    })
    .withAutomaticReconnect()
    .build();
    setCompanyConn(newConnection);
  }, []);
  
  //AdminConn
  useEffect(() => {
    if(adminConn) {
      adminConn.start()
      .then(() => {
        adminConn.on("RefetchTables", (_) => {
          signalRLog("RefetchTables");

          let newNotifications = [...notifications];
          newNotifications.push({
            type: "RefetchTables",
            value: null
          })
          setNotifications(newNotifications);
        })
        
        adminConn.on("RefetchTable", (obj) => {
          signalRLog("RefetchTable", obj);

          let newNotifications = [...notifications];
          newNotifications.push({
            type: "RefetchTable",
            value: obj
          })
          setNotifications(newNotifications);
        })



        adminConn.on("RefetchTableAccessConfigs", (obj) => {
          signalRLog("RefetchTableAccessConfigs");

          let newNotifications = [...notifications];
          newNotifications.push({
            type: "RefetchTableAccessConfigs",
            value: null
          })
          setNotifications(newNotifications);
        })

        adminConn.on("RefetchStyleHeaders", (obj) => {
          signalRLog("RefetchStyleHeaders");

          let newNotifications = [...notifications];
          newNotifications.push({
            type: "RefetchStyleHeaders",
            value: null
          })
          setNotifications(newNotifications);
        })

        adminConn.on("RefetchUsers", (obj) => {
          signalRLog("RefetchUsers");

          let newNotifications = [...notifications];
          newNotifications.push({
            type: "RefetchUsers",
            value: null
          })
          setNotifications(newNotifications);
        })
      })
      console.log("CONNECTED TO ADMIN")
    }
  }, [adminConn]);

  //CompanyConn
  useEffect(() => {
    if(companyConn) {
      companyConn.start()
      .then(() => {
        companyConn.on("RefetchTableRows", (obj) => {
          signalRLog("RefetchTableRows", obj);

          let newNotifications = [...notifications];
          newNotifications.push({
            type: "RefetchTableRows",
            value: obj
          })
          setNotifications(newNotifications);
        })    
        
        companyConn.on("SetAsOnline", (userId) => {
          signalRLog("SetAsOnline", userId);

          let newNotifications = [...notifications];
          newNotifications.push({
            type: "SetAsOnline",
            value: userId
          })
          setNotifications(newNotifications);
        })

        companyConn.on("SetAsOffline", (userId) => {
          signalRLog("SetAsOffline", userId);

          let newNotifications = [...notifications];
          newNotifications.push({
            type: "SetAsOffline",
            value: userId
          })
          setNotifications(newNotifications);
        })

        companyConn.on("LocationShared", (loc) => {
          signalRLog("LocationShared", loc);

          let newNotifications = [...notifications];
          newNotifications.push({
            type: "LocationShared",
            value: loc
          })
          setNotifications(newNotifications);
        })

        companyConn.on("getConnectionid", function (configId) {
          console.log("connectinid:", configId);
        });
        
      })
      console.log("CONNECTED TO COMPANY")
    }
  }, [companyConn]);

  function signalRLog(type, message) {
    console.log("signalR - " + type + " - " + JSON.stringify(message));
  }

  return(
    <SignalRContext.Provider value={{notifications, lastNotification}}>
      {children}
    </SignalRContext.Provider>
  )
}

export const useSignalR = () => useContext(SignalRContext);

//signalR END