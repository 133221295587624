import { useEffect, useState } from "react";
import { useTheme } from "@emotion/react";
import { Autocomplete, Box, Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, MenuItem, Select, TextField, Typography } from "@mui/material";
import { callApiAsync, callApiRawAsync } from "../../js/helper";
import { GridToolbarContainer, useGridApiRef } from "@mui/x-data-grid";
import { Add, DeleteForever, DeleteForeverOutlined, InfoOutlined, Save } from "@mui/icons-material";
import {v4 as uuidv4} from 'uuid';
import { useServerData } from "../../providers/DataProvider";
import { ADMIN_URL, GATEWAY_URL } from "../../js/defines";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import TimedButton from "../../widgets/components/TimedButton";
import { CustomMultiSelectEditComponent } from "../../widgets/webView/DataGridEditComponents";


function GroupConfiguration({bearerToken}) {
  const theme = useTheme();

  const { cfgs, setStatusMsg } = useServerData();
  const apiRef = useGridApiRef();


  const [users, setUsers] = useState([]);

  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  useEffect(() => {
    let newGroup = groups.find((i) => i?._id?.$oid == selectedGroup?._id?.$oid)
    if(newGroup) setSelectedGroup(newGroup)
  }, [groups]);


  const [groupToDelete, setGroupToDelete] = useState(null);

  const [processing, isProcessing] = useState(false);

  function fetchGroups() {
    let url = GATEWAY_URL + ADMIN_URL + "/api/TableAccessConfigs";
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + bearerToken);
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow"
    };
    callApiAsync(url, requestOptions, (response) => {
      let newGroups = response.filter((i) => i.CompanyId == 0);

      newGroups.map((group) => {
        return group?.AllowedTables?.map((i, idx) => { i.id = idx; return i; }) ?? []
      })
      setGroups(newGroups);
    });
  }

  function fetchUsers() {
    let url = GATEWAY_URL + ADMIN_URL + "/api/Users/GetUsers";
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + bearerToken);
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow"
    };
    callApiAsync(url, requestOptions, (response) => {
      setUsers(response);
    });
  }

  function changeProperty(key, value) {
    let newGroup = {...selectedGroup};
    newGroup[key] = value;
    setSelectedGroup(newGroup)
  }

  const [selectedTables, setSelectedTables] = useState([]);
  function onSelectionModel(row) {
    setSelectedTables(row);
  }

  function createNewGroup() {
    setSelectedGroup({GroupName: "New Group", CompanyId: 0, isNew: true})
  }

  function groupPOSTPUT() {
    let tmpGroup = JSON.parse(JSON.stringify(selectedGroup));

    let isNew = tmpGroup.isNew;

    //Rows
    const rowModels = apiRef.current.getRowModels();
    let allRows = Array.from(rowModels.values());
    allRows = allRows?.map((rule) => {
      return rule;
    });
    tmpGroup.AllowedTables = allRows;
    delete tmpGroup?.isNew;

    let url = GATEWAY_URL + ADMIN_URL + "/api/TableAccessConfigs" + (isNew ? "" : ("/" + tmpGroup._id.$oid));
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + bearerToken);
    myHeaders.append("Content-Type", "application/json");
    const requestOptions = {
      method: isNew ? "POST" : "PUT",
      headers: myHeaders,
      redirect: "follow",
      body: JSON.stringify(tmpGroup)
    };
    isProcessing(true);
    callApiRawAsync(url, requestOptions, (response) => {
      console.log(response)
      if(response.status < 300) {
        onReload();
        setStatusMsg({
          type: "Success",
          message: "Group saved successfully!"
        })
      }
      isProcessing(false);
    });
  }

  function onReload() {
    fetchUsers();
    fetchGroups();
  }

  function removeGroup() {
    let url = GATEWAY_URL + ADMIN_URL + "/api/TableAccessConfigs/" + selectedGroup?._id?.$oid;
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + bearerToken);
    myHeaders.append("Content-Type", "application/json");
    const requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow"
    };

    console.log(requestOptions)

    callApiRawAsync(url, requestOptions, (response) => {
      if(response.status < 300) {
        setSelectedGroup(null);
        setGroupToDelete(null);
        onReload();

        setStatusMsg({
          type: "Success",
          message: "Group deleted successfully!"
        })
      }
    });
  }

  const renderEditRole = (params) => {
    const rowModels = apiRef.current.getRowModels();
    let allRows = Array.from(rowModels.values());
    return (
      <Select
        value={params.value || ''}
        onChange={(event) => params.api.setEditCellValue({ id: params.id, field: params.field, value: event.target.value }, event)}
        autoFocus
        fullWidth
      >
        {params.colDef.valueOptions.map((option) => <MenuItem value={option.id} disabled={(allRows.findIndex((j) => j.TableId === option.id) > -1)}>{option.label}</MenuItem>)}
      </Select>
    );
  };

  const styleRuleCols = [
    { field: 'id', headerName: 'ID', width: 50, editable: false },
    {
      field: 'TableId',
      headerName: 'Table',
      type: "singleSelect",
      getOptionLabel: (value) => value.label,
      getOptionValue: (value) => value.id,
      valueOptions: cfgs.map((i) => ({ id: i.id, label: i.tableName})),
      renderEditCell: renderEditRole,
      width: 150,
      editable: true,
      flex: 3
    },
    {
      field: 'Filter',
      headerName: 'Filter',
      width: 110,
      editable: true,
      flex: 3
    },
    {
      field: 'VisibleColumns',
      headerName: 'Visible Columns',
      type: "string",
      getOptionLabel: (value) => value.label,
      getOptionValue: (value) => value.id,
      renderCell: (params) => {
        let table = cfgs.find((i) => i.id == params.row.TableId);
        if(!table) return [];
        let config = {
          listItems: table.columns.map((i) => ({ key: i.key, value: i.columnName }))
        }
        let keys = typeof(params.value) == "object" ? params.value : (params.value != undefined && params.value != "" ? [params.value] : []);
        let configurationsJson2 = config;
        return(
          keys.map((entry, entryIdx) => {
            let objSingle = configurationsJson2.listItems ? configurationsJson2.listItems.sort((a, b) => a.key - b.key).find((option) => option.key == entry) : ""
            return(
              <Chip key={"VisibleColumns" + params.id + entryIdx} size='small' color={"default"} label={objSingle ? objSingle.value : entry} style={{marginInline: "2px"}}></Chip>
            );
          })
        );
      },
      renderEditCell: (params) => {
        let table = cfgs.find((i) => i.id == params.row.TableId);
        if(!table) return [];
        let column = {
          configurationsJson: JSON.stringify({
            listItems: table.columns.map((i) => ({ key: i.key, value: i.columnName }))
          })
        }
        return(<CustomMultiSelectEditComponent {...params} column={column}/>);
      },
      width: 110,
      editable: true,
      flex: 3
    },
    {
      field: 'EditableColumns',
      headerName: 'Editable Columns',
      type: 'string',
      getOptionLabel: (value) => value.label,
      getOptionValue: (value) => value.id,
      renderCell: (params) => {
        let table = cfgs.find((i) => i.id == params.row.TableId);
        if(!table) return [];
        let config = {
          listItems: table.columns.map((i) => ({ key: i.key, value: i.columnName }))
        }
        let keys = typeof(params.value) == "object" ? params.value : (params.value != undefined && params.value != "" ? [params.value] : []);
        let configurationsJson2 = config;
        return(
          keys.map((entry, entryIdx) => {
            let objSingle = configurationsJson2.listItems ? configurationsJson2.listItems.sort((a, b) => a.key - b.key).find((option) => option.key == entry) : ""
            return(
              <Chip key={"VisibleColumns" + params.id + entryIdx} size='small' color={"default"} label={objSingle ? objSingle.value : entry} style={{marginInline: "2px"}}></Chip>
            );
          })
        );
      },
      renderEditCell: (params) => {
        let table = cfgs.find((i) => i.id == params.row.TableId);
        if(!table) return [];
        let column = {
          configurationsJson: JSON.stringify({
            listItems: table.columns.map((i) => ({ key: i.key, value: i.columnName }))
          })
        }
        return(<CustomMultiSelectEditComponent {...params} column={column}/>);
      },
      width: 110,
      editable: true,
      flex: 3
    },
    {
      field: 'CanCreate',
      headerName: 'Can Create',
      type: 'boolean',
      width: 110,
      editable: true,
      flex: 1
    },
    {
      field: 'CanEdit',
      headerName: 'Can Edit',
      type: 'boolean',
      width: 110,
      editable: true,
      flex: 1
    },
    {
      field: 'CanDelete',
      headerName: 'Can Delete',
      type: 'boolean',
      width: 110,
      editable: true,
      flex: 1
    }
  ];
  
  useEffect(() => {
    fetchUsers();
    fetchGroups();
  }, []);

  console.log(groups)

  let allUsers = users.map((j) => ({id: j.id, label: `${j.userName} - ${j.fullname}`}))
  let usersInGroup = users.filter((i) => i.tableAccessConfigId == selectedGroup?._id?.$oid).map((j) => ({id: j.id, label: `${j.userName} - ${j.fullname}`}))

  return (
    <div style={{ display: "flex", backgroundColor: theme.palette.background.default, flexDirection: "column", width: "100%", height: "calc(100vh)", overflow: "hidden" }}>
      <div style={{margin: "30px", display: "flex", flexDirection: "column", gap: "20px"}}>
        <Typography fontSize={30} fontWeight={600} color={theme.palette.text.primary}>Group Configurator</Typography>

        <div style={{display: "flex", gap: "20px"}}> 
          <Autocomplete
            id="free-solo-demo"
            fullWidth
            size="small"
            value={groups?.map((group) => ({id: group._id.$oid, label: group?.GroupName ?? "Unknown"})).find((i) => i.id == selectedGroup?._id?.$oid ) ?? null}
            options={groups?.map((group) => ({id: group._id.$oid, label: group?.GroupName ?? "Unknown"}))}
            renderInput={(params) => <TextField {...params} label="Groups" />}
            onChange={(option, value) => {
              let selGroup = groups.find((group) => group._id.$oid === (value != null ? value.id : null));
              setSelectedGroup(selGroup);
            }}
          />

          <IconButton onClick={() => createNewGroup()}><Add/></IconButton>
          <IconButton disabled={!selectedGroup} onClick={() => setGroupToDelete(selectedGroup)}><DeleteForever style={{color: "red"}}/></IconButton>
          <IconButton disabled={!selectedGroup} onClick={() => { groupPOSTPUT() }}>{!processing ? <Save/> : <CircularProgress size={"24px"}/>}</IconButton>
        </div>

        <>
          <div style={{display: "flex", flexDirection: "column", gap: 64}}>
            <div style={{display: "flex", flexDirection: "column", gap: 16, width: "50%", alignSelf: "center"}}>
              <TextField disabled={!selectedGroup} size="small" label="Group Name" value={selectedGroup?.GroupName ?? ""} onChange={(e) => changeProperty("GroupName", e.target.value)}></TextField>
              <TextField disabled={!selectedGroup} size="small" label="Description" value={selectedGroup?.Description ?? ""} onChange={(e) => changeProperty("Description", e.target.value)}></TextField>

              <Autocomplete
                fullWidth
                multiple
                disabled={!selectedGroup}
                size="small"
                value={usersInGroup}
                options={allUsers}
                renderInput={(params) => <TextField {...params} label="Users in Group" />}
                isOptionEqualToValue={(option, value) => option.id == value.id}
              />
            </div>
            <Box sx={{ height: "calc(100vh - 535px)", width: '100%' }}>
              <DataGridPremium
                apiRef={apiRef}
                rows={selectedGroup?.AllowedTables ?? []}
                columns={styleRuleCols}
                checkboxSelection
                onRowSelectionModelChange={onSelectionModel}
                editMode="row"
                density="compact"
                slots={{
                  toolbar: EditToolbar
                }}
                slotProps={{
                  toolbar: {
                    selectedGroup,
                    selectedTables,
                    onReload,
                    apiRef
                  }
                }}
              />
            </Box>
          </div>

          <Dialog open={groupToDelete != null}>
            <DialogTitle>
              <div style={{display: "flex", gap: 8}}>
                <InfoOutlined style={{alignSelf: "center"}} color='error'/>
                <Typography fontSize={20}>Delete Group</Typography>
              </div>
            </DialogTitle>
            <DialogContent>
              Do you want to delete "{groupToDelete?.GroupName}"?
            </DialogContent>
            <DialogActions>
              <Button style={{ color: "grey", width: "115px" }} onClick={() => setGroupToDelete(null)}>
                Cancel
              </Button>
              <TimedButton
                style={{ width: "115px" }}
                color={"error"}
                variant="contained"
                onClick={() => { removeGroup(groupToDelete) }}
                autoFocus
                timeout={10}
              >
                Delete
              </TimedButton>
            </DialogActions>
          </Dialog>
        </>

      </div>
    </div>
  );
}

export default GroupConfiguration;


function EditToolbar(props) {
  const { selectedGroup, selectedTables, apiRef } = props;

  const handleClick = () => {
    const id = uuidv4();
    let newItem = {
      id: id,
      CanCreate: true,
      CanDelete: true,
      CanEdit: true,
      EditableColumns: [],
      Filter: "",
      TableId: null,
      VisibleColumns: [],
      isNew: true,
    }
    apiRef.current.updateRows([newItem]);
  };

  const handleDelete = () => {
    apiRef.current.updateRows(selectedTables.map((id) => ({id, _action: "delete"})))
  };

  return (
    <GridToolbarContainer>
      <IconButton disabled={!selectedGroup} onClick={handleDelete}><DeleteForeverOutlined/></IconButton>
      <IconButton disabled={!selectedGroup} onClick={handleClick}><Add/></IconButton>
    </GridToolbarContainer>
  );
}