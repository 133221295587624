import { useTheme } from '@emotion/react';
import { Add, Archive, ArchiveOutlined, Close, ContentCopyOutlined, CopyAll, DeleteForever, Download, EditRoad, FormatPaint, FormatPaintOutlined, Map, MapOutlined, OpenInNew, Place, PlaceOutlined, PostAddOutlined, Redo, Refresh, Restore, SaveOutlined, Undo, WarningAmberOutlined } from '@mui/icons-material';
import { Autocomplete, Box, Button, ButtonBase, Checkbox, Chip, CircularProgress, darken, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, IconButton, LinearProgress, Menu, MenuItem, Paper, Popover, Popper, styled, Switch, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Tooltip, Typography } from '@mui/material';
import { DataGridPremium, GRID_CHECKBOX_SELECTION_COL_DEF, GridEditDateCell, GridEditInputCell, GridRowModes, GridToolbarContainer, GridToolbarQuickFilter, gridClasses, useGridApiRef } from '@mui/x-data-grid-premium';
import ImageIcon from '@mui/icons-material/Image';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { callApiAsync, fillTemplate,  geoJSONToKML, getShouldInvertFromHex, handleFileDownload, ObjectID, openUrl } from '../../js/helper';
import ExportButton from '../export/export';
import { SelectedPoiActions, useSelectedPoiList } from '../../context/SelectedPoiProvider';
import { muiTypes } from '../../js/defines';
import { useMap } from '../../context/MapProvider';
import { useTableController } from '../../context/TableProvider';
import { useServerData } from '../../providers/DataProvider';
import TimedButton from '../components/TimedButton';
import ImportDialog from './ImportDialog';
import { CustomAssignedUsersEditComponent, CustomBooleanEditComponent, CustomDynamicSelectEditComponent, CustomEditInputCell, CustomFileEditComponent, CustomImageEditComponent, CustomLocationEditComponent, CustomMultiSelectEditComponent, CustomSingleSelectEditComponent, CustomVectorOverlayEditComponent, ParentEditCell, SignatureCell } from './DataGridEditComponents';


function isOverflown(element) {
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  );
}

const GridCellExpand = memo(function GridCellExpand(props) {
  const { width, value } = props;
  const wrapper = useRef(null);
  const cellDiv = useRef(null);
  const cellValue = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showFullCell, setShowFullCell] = useState(false);
  const [showPopper, setShowPopper] = useState(false);

  const handleMouseEnter = () => {
    const isCurrentlyOverflown = isOverflown(cellValue.current);
    setShowPopper(isCurrentlyOverflown);
    setAnchorEl(cellDiv.current);
    setShowFullCell(true);
  };

  const handleMouseLeave = () => {
    setShowFullCell(false);
  };

  useEffect(() => {
    if (!showFullCell) {
      return undefined;
    }

    function handleKeyDown(nativeEvent) {
      if (nativeEvent.key === 'Escape') {
        setShowFullCell(false);
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [setShowFullCell, showFullCell]);

  return (
    <Box
      ref={wrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        alignItems: 'center',
        lineHeight: '24px',
        width: '100%',
        height: '100%',
        position: 'relative',
        display: 'flex',
        zIndex: 10,
      }}
    >
      <Box
        ref={cellDiv}
        sx={{
          height: '100%',
          width,
          display: 'block',
          position: 'absolute',
          top: 0,
          zIndex: 10,
        }}
      />
      <Box
        ref={cellValue}
        sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
      >
        {value}
      </Box>
      {showPopper && (
        <Popper
          open={showFullCell && anchorEl !== null}
          anchorEl={anchorEl}
          style={{ width, marginLeft: -17, zIndex: 10 }}
        >
          <Paper
            elevation={1}
            style={{ minHeight: wrapper.current.offsetHeight - 3 }}
          >
            <Typography noWrap={false} variant="body2" style={{ padding: 8, maxWidth: "300px" }}>
              {value}
            </Typography>
          </Paper>
        </Popper>
      )}
    </Box>
  );
});


function CustomToolbar(props) {
  const theme = useTheme();

  const { cfgs, selectedCfg, styles, dataEntries, selectedRows, mapConfigs, setMapConfigs, selectedStyles, selectedStyle, showArchived, gisStyles, selectedTableRows, selectedPoisByUniqueId, setShowArchived, setSelectedStyles, getTableRows, groupTableCfg } = useServerData();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl != null);
  const handleClick = (event) => {
    if(selectedCfg != null) {
      setAnchorEl(event.currentTarget);
    }
  };

  function onGisStyleChanged(uniqueKey, id) {
    var newSelectedStyles = {...selectedStyles};
    if(newSelectedStyles[uniqueKey] === id) {
      newSelectedStyles[uniqueKey] = null;
    } else {
      newSelectedStyles[uniqueKey] = id;

      //Activate mapIcons on Map
      let newMapConfigs = {...mapConfigs};
      let cfg = newMapConfigs[selectedCfg.uniqueKey];
      if(cfg == null) {
        cfg = {}
        newMapConfigs[uniqueKey] = cfg;
      }
      cfg["showIcons"] = true;
      cfg["showIconsText"] = true;
      setMapConfigs(newMapConfigs);
    }
    setSelectedStyles(newSelectedStyles);
  }

  function handleShowArchived() {
    let newShowArchived = {...showArchived};
    newShowArchived[selectedCfg?.uniqueKey] = !newShowArchived[selectedCfg?.uniqueKey]
    setShowArchived(newShowArchived);

    getTableRows(selectedCfg, newShowArchived)
  }

  let showIconsActive = useMemo(() => {return(selectedCfg?.uniqueKey ? mapConfigs && mapConfigs[selectedCfg?.uniqueKey] ? mapConfigs[selectedCfg?.uniqueKey].showIcons : false : false)}, [mapConfigs, selectedCfg?.uniqueKey]);
  let showLayerActive = useMemo(() => {return(selectedCfg?.uniqueKey ? mapConfigs && mapConfigs[selectedCfg?.uniqueKey] ? mapConfigs[selectedCfg?.uniqueKey].showLayer : false : false)}, [mapConfigs, selectedCfg?.uniqueKey]);
  
  let locCol = useMemo(() => {return(selectedCfg ? selectedCfg.columns.find((row) => row.fieldType == "Location" ) : null)}, [mapConfigs, selectedCfg]);
  let locColVisible = useMemo(() => columnVisible(locCol, groupTableCfg), [locCol, groupTableCfg]);

  let mapCol = useMemo(() => {return(selectedCfg ? selectedCfg.columns.find((row) => row.fieldType == "Map" ) : null)}, [mapConfigs, selectedCfg]);
  let mapColVisible = useMemo(() => columnVisible(mapCol, groupTableCfg), [mapCol, groupTableCfg]);
  let mapColEditable = useMemo(() => columnEditable(mapCol, groupTableCfg), [mapCol, groupTableCfg]);

  let parentList = []
  let selectedValues = useMemo(() => {return(props.parentGuids ? parentList.filter((entry) => props.parentGuids.includes(entry.key))[0] : null)}, [props.parentGuids, parentList]);

  let parentGuids = useMemo(() => selectedRows[selectedCfg?.parentUniqueKey], [selectedCfg, selectedRows]);

  return (
    <GridToolbarContainer style={{backgroundColor: theme.palette.background.default, padding: "0px", gap: "6px", marginInline: "2px"}}>

      {selectedCfg != null && selectedCfg.parentUniqueId ?
        <Autocomplete
        size='small'
        style={{width: "200px"}}
        value={selectedValues ? selectedValues : null}
        options={parentList}
        getOptionLabel={(option) => option.value}
        getOptionKey={(option) => option.key}
        onChange={(e, value) => {
          props.onRowSelectionModelChange(selectedCfg.parentUniqueId, value ? [value.key] : [], true, selectedCfg);
        }}
        renderInput={(params) => <TextField variant='outlined' placeholder='Haupteintrag' {...params} InputProps={{...params.InputProps, style:{ ...params.InputProps.style, height: "36px" }}}/>}
      /> : null}
      
      {/*Filter*/}
      <GridToolbarQuickFilter variant='outlined' size='small' style={{marginBlock: "2px", padding: "0px"}} inputProps={{style: {height: "19px", margin: "0px"}}} />

      {/*Export*/}
      <Divider orientation="vertical" variant='middle' flexItem/>
      <ExportButton
        poiList={dataEntries}
        configs={cfgs}
        selectedPoiConfig={selectedCfg}
        gisStyles={gisStyles}
        dataGridRef={props.dataGridRef}
        userState={props.userState}
        disabled={selectedCfg == null}
        userSettings={props.userSettings}
      />
      
      {/*Table Control*/}
      <Divider orientation="vertical" variant='middle' flexItem/>
      <Tooltip placement='top' title={"Aktualisieren"}>
        <span>
          <IconButton size='small' disabled={!(selectedCfg != null)} onClick={() => getTableRows(selectedCfg)}><Refresh/></IconButton>
        </span>
      </Tooltip>
      <Tooltip placement='top' title={showArchived[selectedCfg?.uniqueKey] ?? false == true ? "Archivierte ausblenden" : "Archivierte einblenden"}>
        <span>
          <IconButton size='small' disabled={!(selectedCfg != null)} onClick={handleShowArchived}>{showArchived[selectedCfg?.uniqueKey] ?? false == true ? <Archive/> : <ArchiveOutlined/>}</IconButton>
        </span>
      </Tooltip>
      <Divider orientation="vertical" variant='middle' flexItem/>
      <Tooltip placement='top' title={"Änderungen verwerfen"}>
        <span>
          <IconButton size='small' disabled={!(selectedCfg != null && props.hasUnsavedRows)} onClick={() => props.discardChanges()}><Restore/></IconButton>
        </span>
      </Tooltip>
      <Tooltip placement='top' title={"Rückgänging / Coming soon..."}>
        <span>
          <IconButton size='small' onClick={() => props.handleUndo()} disabled/*disabled={props.unsavedChangesRef.current.undoList.length === 0 || props.unsavedChangesRef.current.currentState === 0}*/><Undo/></IconButton>
        </span>
      </Tooltip>
      <Tooltip placement='top' title={"Wiederherstellen / Coming soon..."}>
        <span>
          <IconButton size='small' onClick={() => props.handleRedo()} disabled/*disabled={props.unsavedChangesRef.current.undoList.length === 0 || props.unsavedChangesRef.current.currentState === props.unsavedChangesRef.current.undoList.length - 1}*/><Redo/></IconButton>
        </span>
      </Tooltip>
      <Tooltip placement='top' title={"Geometrie editieren"}>
        <span>
          <IconButton size='small' disabled={!(selectedPoisByUniqueId != null && selectedPoisByUniqueId.length == 1 && mapColVisible && mapColEditable) || !(groupTableCfg?.canEdit ?? true)} style={{color: props.editGeometry == true ? theme.palette.primary.main : null}} onClick={() => props.onEditGeometry()}><EditRoad/></IconButton>
        </span>
      </Tooltip>
      <Tooltip placement='top' title={"Eintrag löschen"}>
        <span>
          <IconButton size='small' disabled={!(selectedCfg != null && selectedTableRows.length > 0) || !(groupTableCfg?.canDelete ?? true)} onClick={() => props.deleteEntriesDialog()}><DeleteForever/></IconButton>
        </span>
      </Tooltip>
      <Tooltip placement='top' title={"Änderungen speichern"}>
        <span>
          <IconButton size='small' disabled={!(selectedCfg != null && (props.hasUnsavedRows && !props.isSaving)) || !(groupTableCfg?.canEdit ?? true)} onClick={() => props.saveChanges()}>{!props.isSaving ? <SaveOutlined/> : <CircularProgress size={"24px"}/>}</IconButton>
        </span>
      </Tooltip>
      <Divider orientation="vertical" variant='middle' flexItem/>
      <Tooltip placement='top' title={"Datenimport"}>
        <span>
          <IconButton size='small' disabled={!((selectedCfg != null && !selectedCfg.parentUniqueKey) || (selectedCfg != null && selectedCfg.parentUniqueKey) && parentGuids?.length == 1) || !(groupTableCfg?.canCreate ?? true)} onClick={() => props.setImportDlgOpen(true)}><PostAddOutlined/></IconButton>
        </span>
      </Tooltip>
      <Tooltip placement='top' title={"Eintrag erstellen"}>
        <span>
          <IconButton size='small' disabled={!((selectedCfg != null && !selectedCfg.parentUniqueKey) || (selectedCfg != null && selectedCfg.parentUniqueKey) && parentGuids?.length == 1) || !(groupTableCfg?.canCreate ?? true)} onClick={() => props.createEntry()}><Add/></IconButton>
        </span>
      </Tooltip>

      <Box sx={{ flexGrow: 1 }}/>
      {/*<Tooltip placement='top' title={showIconsActive ? "Markierung ausblenden" : "Markierung einblenden"}>*/}
          <IconButton
            size='small'
            disabled={selectedCfg == null || !locColVisible}
            style={{color: showIconsActive ? theme.palette.primary.main : null}}
            onClick={() => !props.disableButtons ? props.onMapConfigChanged(selectedCfg?.uniqueKey, "showIcons", !showIconsActive) : null}
          >
            {showIconsActive ? <Place/> : <PlaceOutlined/>}
          </IconButton>
      {/*</Tooltip>*/}
      {/*<Tooltip placement='top' title={showLayerActive ? "Geometrien ausblenden" : "Geometrien einblenden"}>*/}
          <IconButton
            size='small'
            disabled={selectedCfg == null || !mapColVisible}
            style={{color: showLayerActive ? theme.palette.primary.main : null}}
            onClick={() => !props.disableButtons ? props.onMapConfigChanged(selectedCfg?.uniqueKey, "showLayer", !showLayerActive) : null}
          >
            {showLayerActive ? <Map/> : <MapOutlined/>}
          </IconButton>
      {/*</Tooltip>*/}
      {/*<Tooltip placement='top' title="Style wählen">*/}
          <IconButton
            size='small'
            onClick={handleClick}
            disabled={selectedCfg == null || (!locColVisible && !mapColVisible)}
            style={{color: selectedStyle != "" || selectedStyle != "" ? theme.palette.primary.main : null}}
          >
            {selectedStyle != "" || selectedStyle != "" ? <FormatPaint/> : <FormatPaintOutlined/>}
          </IconButton>
      {/*</Tooltip>*/}
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        slotProps={{
          paper: {
            style: {
              paddingInline: 8
            }
          }
        }}
        elevation={1}
        sx={{
          '& .MuiMenu-paper': {
            backgroundColor: theme.palette.background.default,
            borderRadius: "12px"
          },
        }}
        open={open}
        onClose={() => setAnchorEl(null)}
      >
        <Typography style={{textAlign: "center", fontWeight: "bold"}}>Einstellungen</Typography>

        <MenuItem disableRipple style={{ backgroundColor: 'transparent', gap: "4px", cursor: "default" }}>
          Clustern
          <Switch size="small" checked={!mapConfigs[selectedCfg?.uniqueKey]?.noCluster} onClick={(e) => {
            let newMapConfigs = {...mapConfigs};
            let cfg = newMapConfigs[selectedCfg.uniqueKey];
            cfg["noCluster"] = !e.target.checked;
            setMapConfigs(newMapConfigs);
          }}/>
        </MenuItem>

        <MenuItem disableRipple style={{ backgroundColor: 'transparent', gap: "4px", cursor: "default" }}>
          Beschriftung
          <Switch size="small" checked={mapConfigs[selectedCfg?.uniqueKey]?.showIconsText} onClick={(e) => {
            let newMapConfigs = {...mapConfigs};
            let cfg = newMapConfigs[selectedCfg.uniqueKey];
            cfg["showIconsText"] = e.target.checked;
            setMapConfigs(newMapConfigs);
          }}/>
        </MenuItem>

        <Typography style={{textAlign: "center", fontWeight: "bold"}}>Styles</Typography>
        {styles?.filter((i) => i.tableIds?.includes(selectedCfg?.id))?.length > 0 ?
          styles?.filter((i) => i.tableIds?.includes(selectedCfg?.id)).map((style) => (
            <MenuItem
              key={style.id}
              selected={style.id === selectedStyle}
              onClick={() => onGisStyleChanged(selectedCfg.uniqueKey, style.id)}
              style={{gap: "4px"}}
            >
              <Place/>
              {style.styleName}
            </MenuItem>
          )) : <Typography style={{textAlign: "center", fontSize: 14}}>No styles available</Typography>
        }
      </Menu>

      <Dialog
        open={props.itemsToDelete.length > 0}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <div style={{display: "flex", gap: 8}}>
            <WarningAmberOutlined style={{alignSelf: "center"}} color='error'/>
            <Typography fontSize={20}>{props.itemsToDelete.length > 1 ? "Einträge" : "Eintrag"} löschen</Typography>
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.itemsToDelete.length > 1 ? "Sind Sie sicher, dass Sie die ausgewählten " + props.itemsToDelete.length + " Einträge löschen möchten?" : "Sind Sie sicher, dass Sie den ausgewählten Eintrag löschen möchten?"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button style={{ color: "grey", width: "115px" }} onClick={() => props.handleItemsToDelete([])}>
            Abbrechen
          </Button>
          <TimedButton
            style={{ width: "115px" }}
            color={"error"}
            variant="contained"
            loading={props.isDeleting}
            onClick={() => { props.deleteEntries(props.itemsToDelete) }}
            autoFocus
            //timeout={props.itemsToDelete.length > 100 ? 20 : props.itemsToDelete.length > 1 ? 10 : 5}
            timeout={0}
          >
            Löschen
          </TimedButton>
        </DialogActions>
      </Dialog>
    </GridToolbarContainer>
  );
}

const StyledGridOverlay = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  '& .no-rows-primary': {
    fill: '#3D4751',
    ...theme.applyStyles('light', {
      fill: '#AEB8C2',
    }),
  },
  '& .no-rows-secondary': {
    fill: '#1D2126',
    ...theme.applyStyles('light', {
      fill: '#E8EAED',
    }),
  },
}));

function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        width={96}
        viewBox="0 0 452 257"
        aria-hidden
        focusable="false"
      >
        <path
          className="no-rows-primary"
          d="M348 69c-46.392 0-84 37.608-84 84s37.608 84 84 84 84-37.608 84-84-37.608-84-84-84Zm-104 84c0-57.438 46.562-104 104-104s104 46.562 104 104-46.562 104-104 104-104-46.562-104-104Z"
        />
        <path
          className="no-rows-primary"
          d="M308.929 113.929c3.905-3.905 10.237-3.905 14.142 0l63.64 63.64c3.905 3.905 3.905 10.236 0 14.142-3.906 3.905-10.237 3.905-14.142 0l-63.64-63.64c-3.905-3.905-3.905-10.237 0-14.142Z"
        />
        <path
          className="no-rows-primary"
          d="M308.929 191.711c-3.905-3.906-3.905-10.237 0-14.142l63.64-63.64c3.905-3.905 10.236-3.905 14.142 0 3.905 3.905 3.905 10.237 0 14.142l-63.64 63.64c-3.905 3.905-10.237 3.905-14.142 0Z"
        />
        <path
          className="no-rows-secondary"
          d="M0 10C0 4.477 4.477 0 10 0h380c5.523 0 10 4.477 10 10s-4.477 10-10 10H10C4.477 20 0 15.523 0 10ZM0 59c0-5.523 4.477-10 10-10h231c5.523 0 10 4.477 10 10s-4.477 10-10 10H10C4.477 69 0 64.523 0 59ZM0 106c0-5.523 4.477-10 10-10h203c5.523 0 10 4.477 10 10s-4.477 10-10 10H10c-5.523 0-10-4.477-10-10ZM0 153c0-5.523 4.477-10 10-10h195.5c5.523 0 10 4.477 10 10s-4.477 10-10 10H10c-5.523 0-10-4.477-10-10ZM0 200c0-5.523 4.477-10 10-10h203c5.523 0 10 4.477 10 10s-4.477 10-10 10H10c-5.523 0-10-4.477-10-10ZM0 247c0-5.523 4.477-10 10-10h231c5.523 0 10 4.477 10 10s-4.477 10-10 10H10c-5.523 0-10-4.477-10-10Z"
        />
      </svg>
      <Box sx={{ mt: 2 }}>Keine Einträge vorhanden</Box>
    </StyledGridOverlay>
  );
}

function DownloadMapButton({selectedCfg, params}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function downloadGeoJSON(geojsonString, filename = 'data.geojson') {
    const blob = new Blob([geojsonString], { type: 'application/geo+json' });
    const link = document.createElement('a');
    link.download = filename;
    link.href = URL.createObjectURL(blob);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(link.href);
  }

  function downloadKML(geojson, filename = 'data.kml') {
    const kmlString = geoJSONToKML(geojson);
    const blob = new Blob([kmlString], { type: 'application/vnd.google-earth.kml+xml' });
    const link = document.createElement('a');
  
    link.download = filename;
    link.href = URL.createObjectURL(blob);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(link.href);
  }

  let fileName = fillTemplate(selectedCfg, selectedCfg.headerLabelTemplate, params.row);

  return(
    <>
      {params.value ? <Tooltip title={"Geometrien herunterladen"} arrow placement='top'><IconButton style={{verticalAlign: "sub"}} onClick={handleClick}><Download sx={{fontSize: "18px"}}/></IconButton></Tooltip> : null}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => {downloadGeoJSON(params.value, fileName + ".json"); handleClose();}}>GeoJSON</MenuItem>
        <MenuItem onClick={() => {downloadKML(params.value, fileName + ".kml"); handleClose();}}>KML</MenuItem>
      </Menu>
    </>
  )
}


function columnVisible(column, groupTableCfg) {
  let visible = column?.webUIConfig?.visible ?? false;
  if(visible) {
    visible = groupTableCfg != null && groupTableCfg?.visibleColumns?.length > 0 ? groupTableCfg.visibleColumns.includes(column.key) : true;
  }
  return visible;
}

function columnEditable(column, groupTableCfg) {
  let editable = column?.webUIConfig?.editable ?? false;
  if(editable) {
    editable = groupTableCfg?.canEdit ?? true;
  }
  if(editable) {
    editable = (groupTableCfg != null && groupTableCfg?.editableColumns?.length > 0) ? groupTableCfg?.editableColumns?.includes(column?.key) : true;
  }
  return editable;
}


export default function TabViewTable(props) {
  const theme = useTheme();

  const { onFilterModelChange, onRowSelectionModelChange, onMapConfigChanged, companyLogo, editGeometry, disableButtons, userSettings, userState } = props;
  const { mapData, entryToMove, setEntryToMove } = useMap();
  const { BASE_URL, cfgs, serverSyncState, selectedCfg, dataEntries, groupTableCfg, selectedRows, selectedTableRows, dataEntriesByUniqueKey, buddies, me, setStatusMsg } = useServerData();

  const dataGridRef = useGridApiRef(null);
  const [rowModesModel, setRowModesModel] = useState({});
  
  const [importDlgOpen, setImportDlgOpen] = useState(false);

  const [hasUnsavedRows, setHasUnsavedRows] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [itemsToDelete, setItemsToDelete] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false);

  const unsavedChangesRef = useRef({
    unsavedRows: {},
    rowsBeforeChange: {},
    undoList: [],
    currentState: 0
  });

  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedColumn, setSelectecColumn] = useState(null);
  const [contextMenu, setContextMenu] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [showStatistic, setShowStatistic] = useState(false);

  const handleContextMenu = (event) => {
    event.preventDefault();
    if(dataGridRef.current.getColumn) {
      let columnKey = event.currentTarget.getAttribute("data-field");
      let column = dataGridRef.current.getColumn(columnKey);
      setSelectecColumn(column);
    }
    setSelectedRow(event.currentTarget);
    setContextMenu(contextMenu === null ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 } : null
    );
  };

  const createEntry = useCallback(() => {
    setHasUnsavedRows(true);
    let oid = ObjectID();
    let pos = mapData.getMapPosFunction();
    
    let parent;
    if(selectedCfg.parentUniqueKey) {
      let parents = selectedRows[selectedCfg.parentUniqueKey];
      if(parents?.length > 0) {
        parent = parents[0];
      }
    }

    let newEntry = {
      _id: { $oid: oid },
      id: oid,
      CreatedAt: new Date().toISOString(),
      isNew: true
    }

    if(parent) {
      newEntry["parentOids"] = {
        [selectedCfg.parentUniqueKey]: [parent]
      }
    }

    let locationCol = selectedCfg?.columns?.find((i) => i.fieldType == "Location");
    if(locationCol) {
      newEntry[locationCol.key] = {
        lat: pos ? pos.pos[1] : 0,
        long: pos ? pos.pos[0] : 0
      }
    }

    let assignedUsersCol = selectedCfg?.columns?.find((i) => i.fieldType == "AssignedUsers");
    if(assignedUsersCol) {
      newEntry[assignedUsersCol.key] = [me.id];
    }

    //Fill all default values
    selectedCfg.columns.map((col) => {
      console.log(col.defaultValue)
      if(col.defaultValue != "" && col.fieldType != "Label" && col.fieldType != "Button") {
        if(col.fieldType == "MultiList" || col.fieldType == "DynamicList") {
          let defaultValue = JSON.parse(col.defaultValue);
          if(defaultValue.length > 0) {
            newEntry[col.key] = JSON.parse(col.defaultValue);
          }
        } else if(col.fieldType == "Int" || col.fieldType == "Float") {
          newEntry[col.key] = parseFloat(col.defaultValue);
        } else if(col.fieldType == "Bool") {
          newEntry[col.key] = col.defaultValue == "true" ? true : false;
        } else {
          newEntry[col.key] = col.defaultValue;
        }  
      }
    });

    console.log(newEntry)

    props.onTableRowsChanged(selectedCfg?.uniqueKey, [newEntry]);

    //Scroll to top of list
    document.querySelector('.MuiDataGrid-virtualScroller').scrollTop = 0;

    const rowId = newEntry.id;
    unsavedChangesRef.current.unsavedRows[rowId] = newEntry;
    if (!unsavedChangesRef.current.rowsBeforeChange[rowId]) {
      unsavedChangesRef.current.rowsBeforeChange[rowId] = newEntry;
    }
    setHasUnsavedRows(true);
  }, [dataGridRef, dataEntries, selectedCfg, mapData]);


  const createEntries = useCallback((entries) => {
    setHasUnsavedRows(true);

    let parent;
    if(selectedCfg.parentUniqueKey) {
      let parents = selectedRows[selectedCfg.parentUniqueKey];
      if(parents?.length > 0) {
        parent = parents[0];
      }
    }

    let assignedUsersCol = selectedCfg?.columns?.find((i) => i.fieldType == "AssignedUsers");

    entries?.map((entry) => {
      entry["id"] = entry._id.$oid;
      entry["isNew"] = true;
      //Set Parent if available
      if(parent) {
        entry["parentOids"] = {
          [selectedCfg.parentUniqueKey]: [parent]
        }
      }

      if(assignedUsersCol) {
        entry[assignedUsersCol.key] = [me.id];
      }

      const rowId = entry.id;
      unsavedChangesRef.current.unsavedRows[rowId] = entry;
      if (!unsavedChangesRef.current.rowsBeforeChange[rowId]) {
        unsavedChangesRef.current.rowsBeforeChange[rowId] = entry;
      }
    })

    dataGridRef.current.updateRows(entries);

  }, [dataGridRef, selectedCfg, mapData]);



  const { setProcessRowUpdate } = useTableController();
  useEffect(() => {
    setProcessRowUpdate(processRowUpdate);
  }, [dataEntries, selectedCfg]);

  const processRowUpdate = useCallback((newRow, oldRow, params) => {
    const rowId = newRow.id;

    //Single Undo
    unsavedChangesRef.current.unsavedRows[rowId] = newRow;
    if (!unsavedChangesRef.current.rowsBeforeChange[rowId]) {
      unsavedChangesRef.current.rowsBeforeChange[rowId] = oldRow;
    }

    //Update Table Row
    let unsavedPois = Object.entries(unsavedChangesRef.current.unsavedRows).map((entry) => entry[1]);
    let uniqueKey = selectedCfg?.uniqueKey;
    props.onTableRowsChanged(uniqueKey, unsavedPois);
    setHasUnsavedRows(true);
    return newRow;
  }, [dataEntries, selectedCfg]);

  const [geometrySaveDlg, setGeometrySaveDlg] = useState(false);

  const onEditGeometry = useCallback(() => {
    if(props.editGeometry) {
      setGeometrySaveDlg(true);
    } else {
      props.onEditGeometry(!props.editGeometry);
    }
  }, [props.editGeometry]);

  const handleNo = () => {
    setGeometrySaveDlg(false);
    props.onEditGeometry(false);
  };

  const handleYes = async () => {
    let selectedRow = selectedTableRows[0];
    if(selectedRow) {
      let entry = unsavedChangesRef.current.unsavedRows[selectedRow];
      if(entry == null) {
        entry = rows.find((row) => row.id == selectedRow);
      }
      let newEntry = structuredClone(entry);
      let geojson = mapData.getGeometryAsGeoJSONFunction();
      mapData.changeIgnoreZoomOnGeometryFunction(true);

      let mts = new Date().toISOString();
      newEntry[geojson.vectorOverlayKey] = JSON.stringify(geojson.data);
      newEntry["ModifiedAt"] = mts;
      newEntry["fieldsModifiedAt"] = {
        ...newEntry["fieldsModifiedAt"],
        [geojson.vectorOverlayKey]: mts
      }
      processRowUpdate(newEntry, entry);
    }
    setGeometrySaveDlg(false);
    props.onEditGeometry(false);
  };

  const renderConfirmDialog = () => {
    return (
      <Dialog
        open={geometrySaveDlg}
      >
        <DialogTitle>{"Änderungen übernehmen?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Sind Sie sicher, das Sie die Änderungen übernehmen möchten?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleNo}>
            Verwerfen
          </Button>
          <Button variant='contained' onClick={handleYes}>Übernehmen</Button>
        </DialogActions>
      </Dialog>
    );
  };

  const handleUndo = useCallback(() => {
    unsavedChangesRef.current.currentState -= 1;
    let undoItem = unsavedChangesRef.current.undoList[unsavedChangesRef.current.currentState];
    let newRow = unsavedChangesRef.current.unsavedRows[undoItem.guid][undoItem.state];
    dataGridRef.current.updateRows([newRow]);
    console.log(unsavedChangesRef.current)
    return newRow;
  }, []);

  const handleRedo = useCallback(() => {
    unsavedChangesRef.current.currentState += 1;
    let undoItem = unsavedChangesRef.current.undoList[unsavedChangesRef.current.currentState];
    let newRow = unsavedChangesRef.current.unsavedRows[undoItem.guid][undoItem.state];
    dataGridRef.current.updateRows([newRow]);
    console.log(unsavedChangesRef.current)
    return newRow;
  }, []);

  const discardChanges = useCallback(() => {
    setHasUnsavedRows(false);
    Object.values(unsavedChangesRef.current.rowsBeforeChange).forEach((row) => {
      dataGridRef.current.updateRows([row]);
    });
    
    let rowsBeforeChange = Object.entries(unsavedChangesRef.current.rowsBeforeChange).map((entry) => entry[1]);
    //let poiUniqueId = rowsBeforeChange[0].std.poiUniqueId;
    let uniqueKey = selectedCfg?.uniqueKey;
    props.onTableRowsChanged(uniqueKey, rowsBeforeChange);

    unsavedChangesRef.current = {
      unsavedRows: {},
      rowsBeforeChange: {},
      undoList: [],
      currentState: 0
    };
  }, [dataGridRef, unsavedChangesRef, dataEntries, selectedCfg]);


  const saveChanges = useCallback(async () => {
    const header = new Headers();
    header.append("Authorization", "Bearer " + props.bearerToken);
    header.append("Content-Type", "application/json");

    try {
      setIsSaving(true);

      console.log("SAVE CHANGES")

      let unsavedPois = Object.entries(unsavedChangesRef.current.unsavedRows).map((entry) => entry[1]);

      let changedPois = unsavedPois.filter((item) => !item.isNew).map((poi) => {
        return poi;
      });

      let newPois = unsavedPois.filter((item) => item.isNew).map((poi) => {
        return poi;
      });

      let saveSuccessful = true;

      if(changedPois.length > 0) {
        let tableId = selectedCfg?.id;
        let changedEntrys = JSON.parse(JSON.stringify(changedPois));
        changedEntrys = changedEntrys.map((newPoi) => {
          let id = newPoi.id;
          delete newPoi.isNew;

          const patchedItem = {};
          console.log("patchedItem", patchedItem)
          
          if(!newPoi?.fieldsModifiedAt) {
            setIsSaving(false);
            return {
              modifiedAt: newPoi.ModifiedAt,
              rowId: id,
              data: []
            }
          }

          for (const key in newPoi) {
            if (newPoi?.fieldsModifiedAt[key] != null || key == "ModifiedAt") {
              patchedItem[key] = newPoi[key];
            }
          }

          let mts = patchedItem.ModifiedAt;

          delete patchedItem.CreatedAt;
          delete patchedItem.CreatedBy;
          delete patchedItem.ModifiedAt;
          delete patchedItem.ModifiedBy;

          console.log("patchedItem", patchedItem)

          return(
            {
              modifiedAt: mts,
              rowId: id,
              data: patchedItem
            }
          );
        })

        let requestOptions = {
          headers: header,
          method: "PATCH",
          body: JSON.stringify({"rows": changedEntrys}),
          redirect: "follow"
        };
        console.log(requestOptions)
        await new Promise((resolve) => {
          callApiAsync(BASE_URL + "/api/Entries/byId/" + tableId, requestOptions, (response) => {
            if(response != null) {
              props.onTableRowsChanged(selectedCfg.uniqueKey, changedPois);
            } else {
              saveSuccessful = false;
            }
            resolve()
          });
        });
      }

      if(newPois.length > 0) {
        let tableId = selectedCfg?.id;

        let newPoisCopy = JSON.parse(JSON.stringify(newPois));
        newPoisCopy.map((newPoi) => {
          delete newPoi.isNew;
          delete newPoi._id;
        })

        let requestOptions = {
          headers: header,
          method: "POST",
          body: JSON.stringify({"rows": newPoisCopy}),
          redirect: "follow"
        };
        console.log(requestOptions)
        await new Promise((resolve) => {
          callApiAsync(BASE_URL + "/api/Entries/byId/" + tableId, requestOptions, (response) => {
            if(response != null) {
              props.onTableRowsChanged(selectedCfg.uniqueKey, newPois);
            } else {
              saveSuccessful = false;
            }
            resolve()
          });
        });
      }

      setIsSaving(false);
      if(saveSuccessful) {
        setHasUnsavedRows(false);
        unsavedChangesRef.current = {
          unsavedRows: {},
          rowsBeforeChange: {},
          undoList: [],
          currentState: 0
        };
      }
    } catch (error) {
      setIsSaving(false);
    }
  }, [dataGridRef, unsavedChangesRef, dataEntries, selectedCfg]);


  const deleteEntriesDialog = useCallback(() => {
    setItemsToDelete(selectedTableRows);
  }, [dataGridRef, selectedTableRows]);

  function handleItemsToDelete(items) {
    setItemsToDelete(items);
  }

  const deleteEntries = useCallback(async (rows) => {
    let tableId = selectedCfg?.id;
    const header = new Headers();
    header.append("Authorization", "Bearer " + props.bearerToken);
    header.append("Content-Type", "application/json");
    try {
      let requestOptions = {
        headers: header,
        method: "DELETE",
        body: JSON.stringify({
          rowOids: rows
        })
      };

      setIsDeleting(true);
      callApiAsync(BASE_URL + "/api/Entries/byId/" + tableId, requestOptions, (response) => {
        props.onRowSelectionModelChange(selectedCfg.uniqueKey, []);
        setItemsToDelete([]);
        props.onTableRowsChanged(selectedCfg?.uniqueKey, rows, "delete");
        setIsDeleting(false);
      });
    } catch (error) {
      setItemsToDelete([]);
      setIsDeleting(false);
    }



  }, [dataGridRef, selectedTableRows]);

  const getRowClassName = useCallback((params) => {
    const unsavedRow = unsavedChangesRef.current.unsavedRows[params.id];
    if (unsavedRow) {
      return 'row--edited';
    }
    let archiveCol = selectedCfg?.columns.find((i) => i.fieldType == "Archive");  
    if(archiveCol != null && params.row[archiveCol.key] == true) {
      return 'row--archived';
    }
    return '';
  }, [selectedCfg]);



  //Picture Preview
  const [anchorEl, setAnchorEl] = useState(null);
  const [imageUrl, setImageUrl] = useState("");

  // Simulated API call
  const fetchImageUrl = async (rowId) => {
    const response = await fetch(BASE_URL + `/Uploads/Files/${rowId}`);
    const blob = await response.blob();
    
    // Convert blob to a data URL
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  };

  const handleShowPicture = async (event, params) => {
    if(params?.formattedValue?.FileUrl) {
      setAnchorEl(event.currentTarget);
      const url = await fetchImageUrl(params?.formattedValue?.FileUrl); // Assume `params.row.id` is the identifier
      setImageUrl({
        params,
        url
      });
    }
  };

  const handleHidePicture = () => {
    setAnchorEl(null);
    setImageUrl("");
  };


  function valueGetterColumn(value, column, row) {
    if(column.field == "_createdBy") {
      let buddy = buddies.find((i) => i.id == row.CreatedBy);
      return(buddy != null ? buddy.fullname : row.CreatedBy);
    }

    if(column.field == "_modifiedBy") {
      let buddy = buddies.find((i) => i.id == row.ModifiedBy);
      return(buddy != null ? buddy.fullname : row.ModifiedBy);
    }

    if(column.field == "_createdAt") {
      return(row.CreatedAt ? new Date(row.CreatedAt) : null);
    }

    if(column.field == "_modifiedAt") {
      return(row.ModifiedAt ? new Date(row.ModifiedAt) : null);
    }

    switch(column.fieldType) {
      case "Date":
      case "DateTime":
        return(value ? new Date(value) : null);

      default:
        return(value);
    }
  }


  //Scripting functions
  function setValue(data, key, value) {
    let mts = new Date().toISOString();
    data[key] = value;
    data["ModifiedAt"] =  mts;
    data.fieldsModifiedAt = {
      ...data.fieldsModifiedAt,
      [key]: mts
    };
  }

  function valueSetterColumn(params, column, row) {
    let mts = new Date().toISOString();
    if(column.fieldType == "Image" || column.fieldType == "File" || column.fieldType == "Signature") {
      if(!params?.base64) {
        return {
          ...row, 
          [column.key]: params,
          ["ModifiedAt"]: mts,
        };
      }
    }

    let newRow = {...row};
    newRow[column.key] = params;
    newRow["ModifiedAt"] =  mts;
    newRow.fieldsModifiedAt = {
      ...newRow.fieldsModifiedAt,
      [column.key]: mts
    };
    
    //OnChange Function
    try {
      if(column?.onChange != null) {
        let entry = {}
        entry.setValue = setValue;
        entry.data = newRow;
        column.onChange(entry, column, params);
        console.log("NEW ROW", entry.data);
        newRow = entry.data;
      }
    } catch(e) {
      console.log(e.toString())
    }
    
    return newRow;
  }


  function renderCell(params, column, row) {
    if(params.field == "_oid") {
      return(params.row?.id);
    }

    if(params.field == "parentOids") {
      let parentOids = params.row?.parentOids;
      if(parentOids == null) return;

      return(
        Object.entries(parentOids).map(([key, value]) => {
          let cfg = cfgs?.find((i) => i.uniqueKey == key);
          if(cfg == null) return;
          return(
            <Chip size='small' color={"primary"} label={`${cfg.tableName} - ${parentOids[cfg.uniqueKey].length}`} style={{marginInline: "2px", backgroundColor: cfg?.tableIconBgColor, color: getShouldInvertFromHex(cfg?.tableIconBgColor) ? "black" : "white"}}></Chip>
          );
        })
      )
    }

    if(params.field == "_rawdata") {
      return(JSON.stringify(params.row, null, 2));
    }

    if(params.field == "_createdAt") {
      return(params?.formattedValue);
    }

    if(params.field == "_createdBy") {
      return(params?.value);
    }

    if(params.field == "_modifiedAt") {
      return(params?.formattedValue);
    }

    if(params.field == "_modifiedBy") {
      return(params?.value);
    }

    switch(column.fieldType) {
      case "String":
      case "MultilineText":
        return(params.value);

      case "Int":
        return(params.value);

      case "Float":
        let fixedValue = 2;
        if(column.maximumFractionDigits) {
          fixedValue = column.maximumFractionDigits;
        }
        return(!isNaN(parseFloat(params.value)) && column.type != "int" ? parseFloat(params.value).toFixed(fixedValue) : params.value);

      case "Bool":
      case "Archive":
        let checked = false;
        if(typeof params.value == "string") {
          checked = params.value != null && params.value == "true";
        } else {
          checked = params.value;
        }
        return(<Checkbox size='small' disabled checked={checked}/>);

      case "SingleList":
        if(column.configurationsJson == null || column.configurationsJson == "") return;

        let configurationsJson = JSON.parse(column.configurationsJson);
        let objSingle = configurationsJson.listItems ? configurationsJson.listItems.sort((a, b) => a.key - b.key).find((option) => option.key == params.value) : ""

        return(params.value != null ? <Chip size='small' label={objSingle?.value ?? params.value} style={{marginInline: "2px", backgroundColor: objSingle?.color, color: objSingle?.color != null ? (getShouldInvertFromHex(objSingle?.color) ? "black" : "white") : "black"}}></Chip> : null)

      case "MultiList":
        let keys = typeof(params.value) == "object" ? params.value : (params.value != undefined && params.value != "" ? [params.value] : []);
        if(column.configurationsJson == null || column.configurationsJson == "") return;
        let configurationsJson2 = JSON.parse(column.configurationsJson);
        
        return(
          keys.map((entry, entryIdx) => {
            let objSingle = configurationsJson2.listItems ? configurationsJson2.listItems.sort((a, b) => a.key - b.key).find((option) => option.key == entry) : ""
            return(
              <Chip key={column.id + params.id + entryIdx} size='small' color={column.id == "send_to" ? "primary" : "default"} label={objSingle ? objSingle.value : entry} style={{marginInline: "2px"}}></Chip>
            );
          })
        );

      case "DynamicList":
        let rowIds = params.value ?? [];
        if(column.configurationsJson == null || column.configurationsJson == "") return;
        let configurationsJson3 = JSON.parse(column.configurationsJson);

        let dynamicCfg = cfgs.find((i) => i.id == configurationsJson3.tableId);
        let dynamicList = dataEntries[dynamicCfg?.uniqueKey]?.map((i) => ({"key": i._id.$oid, "value": fillTemplate(dynamicCfg, dynamicCfg.headerLabelTemplate, i)})) ?? [];

        return(
          rowIds?.map((entry, entryIdx) => {
            let objSingle = dynamicList?.find((option) => option.key == entry);
            return(
              <Chip key={column.id + params.id + entryIdx} size='small' color={column.id == "send_to" ? "primary" : "default"} label={objSingle?.value ?? entry} style={{marginInline: "2px"}}></Chip>
            );
          })
        );

      case "AssignedUsers":
        let users = typeof(params.value) == "object" ? params.value : (params.value != undefined && params.value != "" ? [params.value] : []);
        return(
          users.map((userId, entryIdx) => {
            let objSingle = buddies?.sort((a, b) => b.fullname - a.fullname).find((buddy) => buddy.id == userId)
            return(
              <Chip key={column.id + params.id + entryIdx} size='small' color={"primary"} label={objSingle ? objSingle.fullname : userId} style={{marginInline: "2px"}}></Chip>
            );
          })
        );

      case "Date":
        return(params?.value?.toLocaleDateString());

      case "DateTime":
        return(params?.value?.toLocaleString());

      case "Location":
        let location = params.row[column.key];
        let locString = "";
        if(location) {
          locString = location?.lat?.toFixed(6) + " / " + location?.long?.toFixed(6); 
        }
        return(locString);

      case "File":
        return(
          <div style={{display: "flex"}}>
            {params.value?.FileUrl ? <Tooltip title={"Dokument herunterladen"} arrow placement='top'><IconButton style={{verticalAlign: "sub"}} onClick={(e) => handleFileDownload(params.value, BASE_URL, props.bearerToken, e)}><Download sx={{fontSize: "18px"}}/></IconButton></Tooltip> : null}
            {params.value?.FileName ?? ""}
          </div>
        );

      case "Image":
      case "Signature":
        return(
          <div style={{width: "100%", height: "100%", cursor: "pointer"}}>
            {params.value?.FileUrl ? <Tooltip title={"Bild anzeigen"} arrow placement='top'><IconButton style={{verticalAlign: "sub"}} onMouseDown={(event) => handleShowPicture(event, params)}><ImageIcon sx={{fontSize: "18px"}}/></IconButton></Tooltip> : null}
            {params.value?.FileName ?? ""}
          </div>
        );

      case "Map":
        return(
          <>
            {params.value ? <DownloadMapButton selectedCfg={selectedCfg} params={params}/> : null}
            {params.value ? "Map" : ""}
          </>
        );

      case "Href":
        return(
          <>
            {params.value ? <Tooltip title={"Link öffnen"} arrow placement='top'><IconButton style={{verticalAlign: "sub"}} onClick={(e) => {openUrl(params.value, e); e.stopPropagation(); }}><OpenInNew sx={{fontSize: "18px"}}/></IconButton></Tooltip> : null}
            {params.value && params.value ? params.value : ""}
          </>
        );

      case "Email":
        return(
          <>
            {params.value ? <Tooltip title={"In Zwischenablage kopieren"} arrow placement='top'><IconButton style={{verticalAlign: "sub"}} onClick={(e) => {navigator.clipboard.writeText(params.value); e.stopPropagation(); }}><ContentCopyOutlined sx={{fontSize: "18px"}}/></IconButton></Tooltip> : null}
            {params.value && params.value ? params.value : ""}
          </>
        );

      case "PhoneNumber":
        return(
          <>
            {params.value ? <Tooltip title={"In Zwischenablage kopieren"} arrow placement='top'><IconButton style={{verticalAlign: "sub"}} onClick={(e) => {navigator.clipboard.writeText(params.value); e.stopPropagation(); }}><ContentCopyOutlined sx={{fontSize: "18px"}}/></IconButton></Tooltip> : null}
            {params.value && params.value ? params.value : ""}
          </>
        );

      case "Label":
        return(params.value);

      case "Button":
        return(<ButtonBase onClick={(e) => e.stopPropagation()}  sx={{width: '100%', height: '100%', textAlign: 'left', padding: '8px', boxSizing: 'border-box', color: "#090"}}>{column.columnName}</ButtonBase>)

      default:
        return(JSON.stringify(params.value));
    }
  }

  function renderEditCell(params, column) {
    if(params.field == "_oid") {
      return(params.row._id.$oid);
    }

    if(params.field == "_rawdata") {
      return(JSON.stringify(params.row, null, 2));
    }

    if(column.fieldType == "Location") {
      return(<CustomLocationEditComponent {...params}/>)
    }

    if(params.field == "parentOids") {
      let parentOids = params.row?.parentOids;
      return(<ParentEditCell {...params} parentOids={parentOids}/>)
    }

    switch(column.fieldType) {
      case "Map":
        return(<CustomVectorOverlayEditComponent {...params} username={props.username} password={props.password} column={column} accept={[".kml", ".json", ".geojson", ".gpx"]}/>);
      
      case "Int":
        return(<CustomEditInputCell {...params}/>);
      
      case "SingleList":
        return(<CustomSingleSelectEditComponent {...params} column={column}/>);

      case "MultiList":
        return(<CustomMultiSelectEditComponent {...params} column={column}/>);

      case "DynamicList":
        return(<CustomDynamicSelectEditComponent {...params} column={column}/>);
      
      case "AssignedUsers":
        return(<CustomAssignedUsersEditComponent {...params} column={column}/>);

      case "Bool":
      case "Archive":
        return(
          <CustomBooleanEditComponent {...params} column={column}/>
        );

      case "Date":
      case "DateTime":
        return(
          <GridEditDateCell {...params} column={column}/>
        );

      case "File":
      case "Doc":
        return(<CustomFileEditComponent {...params} username={props.username} password={props.password} column={column} accept={"application/pdf, application/vnd.oasis.opendocument.text, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, audio/wav, audio/mpeg, image/apng, image/jpeg, image/gif"}/>);

      case "Image":
        return(<CustomImageEditComponent {...params} username={props.username} password={props.password} column={column} accept={"image/jpeg, image/png, image/gif"}/>);

      case "Signature":
        return <SignatureCell {...params}/>;

      default:
        return(<GridEditInputCell {...params}/>);
    }
  }

  function handleOnFilterModelChange(filterModel) {
    onFilterModelChange(selectedCfg.uniqueKey, filterModel);
  }

  function handleOnRowSelectionModelChange(row) {
    onRowSelectionModelChange(selectedCfg.uniqueKey, row);
  }

  function generateExportFileName() {
    return props.selectedCfg != null ? props.selectedCfg.tableName : "Neue Tabelle";
  }

  useEffect(() => {
    if(selectedTableRows.length == 1) {
      let id = selectedTableRows[0];
      if(props.appView) {
        setRowModesModel({[id]: { mode: GridRowModes.Edit }});
      } else {
        setRowModesModel({[id]: { mode: GridRowModes.View }});
      }
    }
  }, [selectedTableRows, props.appView]);

  let columns = useMemo(() => {
    let columns = [];

    let parentColumn = {
      field: "parentOids",
      type: "string",
      headerName: "Parent Table",
      width: 150,
      editable: true,
      renderCell: (params) => renderCell(params),
      renderEditCell: (params) => renderEditCell(params, { key: "parentOids", field: "ParentOids" }),
      valueSetter: (params, row) => valueSetterColumn(params, { key: "parentOids", field: "ParentOids" }, row),
    }
    columns.push(parentColumn);

    let createdAtColumn = {
      field: "_createdAt",
      type: "dateTime",
      headerName: "Erstellungsdatum",
      width: 150,
      editable: false,
      valueGetter: (params, row) => valueGetterColumn(params, { field: "_createdAt" }, row),
      renderCell: (params) => renderCell(params)
    }
    columns.push(createdAtColumn);

    let createdByColumn = {
      field: "_createdBy",
      type: "string",
      headerName: "Erstellt von",
      width: 150,
      editable: false,
      valueGetter: (params, row) => valueGetterColumn(params, { field: "_createdBy" }, row),
      renderCell: (params) => renderCell(params)
    }
    columns.push(createdByColumn);


    let modifiedAtColumn = {
      field: "_modifiedAt",
      type: "dateTime",
      headerName: "Änderungsdatum",
      width: 150,
      editable: false,
      valueGetter: (params, row) => valueGetterColumn(params, { field: "_modifiedAt" }, row),
      renderCell: (params) => renderCell(params)
    }
    columns.push(modifiedAtColumn);

    let modifedByColumn = {
      field: "_modifiedBy",
      type: "string",
      headerName: "Geändert von",
      width: 150,
      editable: false,
      valueGetter: (params, row) => valueGetterColumn(params, { field: "_modifiedBy" }, row),
      renderCell: (params) => renderCell(params)
    }
    columns.push(modifedByColumn);

    if(selectedCfg) {
      selectedCfg.columns.sort((a, b) => a?.webUIConfig?.order - b?.webUIConfig?.order).forEach((column) => {
        if(column.fieldType == "NestedTable" || column.fieldType == "ParentOids") return;
        if(!columnVisible(column, groupTableCfg)) return;

        //Register scripting functions
        const context = {};
        try {
          let colCfg = JSON.parse(column.configurationsJson)
          const functionWrapper = new Function('context', colCfg.script);
          functionWrapper(context);
          column.onChange = context.onChange;
        } catch (error) {}

        let newColumn = {
          field: column.key,
          type: muiTypes[column.fieldType],
          headerName: column.columnName,
          flex: 1,
          minWidth: 150,
          editable: column.fieldType == "Button" || column.fieldType == "Label" ? false : columnEditable(column, groupTableCfg),
          pinnable: false,
          groupable: false,
          //valueOptions: column.list ? column.list : [],
          //getOptionLabel: (value) => value.value,
          //getOptionValue: (value) => value.key,
          renderCell: (params) => renderCell(params, column),
          renderEditCell: (params) => renderEditCell(params, column),
          valueGetter: (params, row) => valueGetterColumn(params, column, row),
          valueSetter: (params, row) => valueSetterColumn(params, column, row),
          cellClassName: column.fieldType == "Button" ? 'button-cell' : null,
        }
        columns.push(newColumn);
      })
    }

    if(userState?.userState?.isAdmin || true) {
      let guidColumn = {
        field: "_oid",
        type: "string",
        headerName: "ObjectID",
        width: 150,
        editable: false,
        renderCell: (params) => renderCell(params)
      }
      columns.push(guidColumn);
  
      let rawDataColumn = {
        field: "_rawdata",
        type: "string",
        headerName: "JSON Data",
        width: 150,
        editable: false,
        renderCell: (params) => renderCell(params)
      }
      columns.push(rawDataColumn);
    }

    return(columns);
  }, [dataEntries, selectedCfg, groupTableCfg]);

  let rows = useMemo(() => {
    return(dataEntries[selectedCfg?.uniqueKey]?.sort((a, b) => new Date(b?.CreatedAt) - new Date(a?.CreatedAt))?.map((entry, idx) => {
      var data = entry;
      data["id"] = entry?._id.$oid;
      return data;
    }) ?? []);
  }, [dataEntries, selectedCfg]);

  let selectedTableRowPois = useMemo(() => {
    return dataEntriesByUniqueKey?.filter((poi,i) => selectedTableRows?.includes(poi.id))
  },[dataEntries, selectedCfg, dataEntriesByUniqueKey, selectedTableRows]);


  const { dispatch } = useSelectedPoiList();
  useEffect(() => {
    dispatch({ type: SelectedPoiActions.setSelectedPoi, payload: selectedTableRowPois });
    dispatch({ type: SelectedPoiActions.setSelectedConfigs, payload: props.selectedCfg?.config });
  },[dataEntries, selectedTableRowPois, dataEntriesByUniqueKey, dispatch, selectedCfg])


  let parentGuids = useMemo(() => {return(selectedCfg ? selectedRows[selectedCfg?.parentUniqueKey] : [])}, [selectedCfg, selectedRows]);


  let statisticData = useMemo(() => {
    let obj = {}
    let sum = 0;
    let cnt = selectedTableRowPois ? selectedTableRowPois.length : 0;
    if(selectedTableRowPois && selectedColumn) {
      selectedTableRowPois.map((row) => {
        sum += !isNaN(parseFloat(row[selectedColumn?.field])) ? parseFloat(row[selectedColumn?.field]) : 0;
      });
    }
    obj["Anzahl"] = cnt;
    obj["Summe"] = sum.toFixed(2);
    obj["Querschnitt"] = cnt > 0 ? (sum/cnt).toFixed(2) : 0;
    return(obj);
  }, [showStatistic]);

  return(
    <Box sx={{ height: (props.height-40), width: "calc(100vw - " + (70) + "px)", backgroundColor: theme.palette.background.default, overflow: "hidden"}}>
      {renderConfirmDialog()}
      <DataGridPremium
        apiRef={dataGridRef}
        rows={rows}
        columns={columns}
        rowSelectionModel={selectedTableRows}
        filterModel={props.selectedTableFilters}
        onFilterModelChange={handleOnFilterModelChange}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 100,
            },
          },
          sorting: {
            sortModel: [{ field: 'CreatedAt', sort: 'desc' }]
          },
          pinnedColumns: { 
            left: [GRID_CHECKBOX_SELECTION_COL_DEF.field]
          },
          density: "compact"
        }}
        slots={{
          toolbar: CustomToolbar,
          loadingOverlay: LinearProgress,
          noRowsOverlay: CustomNoRowsOverlay,
        }}
        slotProps={{
          toolbar: {
            userState,
            parentGuids,
            companyLogo,
            itemsToDelete,
            userSettings,
            handleItemsToDelete,
            onMapConfigChanged,
            disableButtons,
            unsavedChangesRef,
            createEntry,
            setImportDlgOpen,
            discardChanges,
            handleUndo,
            handleRedo,
            saveChanges,
            editGeometry,
            onEditGeometry,
            deleteEntriesDialog,
            onRowSelectionModelChange,
            deleteEntries,
            hasUnsavedRows,
            isSaving,
            isDeleting,
            dataGridRef,
            csvOptions: { fileName: generateExportFileName() }, 
            printOptions: { fileName: generateExportFileName() }
          },
          cell: {
            onContextMenu: handleContextMenu,
          },
        }}
        style={{
          borderStyle: "none"
        }}
        disableAggregation
        disableRowGrouping
        disableRowSelectionOnClick={props.editGeometry}
        loading={serverSyncState == -2}
        pageSizeOptions={[100]}
        checkboxSelection={!props.appView}
        rowModesModel={rowModesModel}
        onRowSelectionModelChange={!(props.serverSyncState > 0) ? (handleOnRowSelectionModelChange ? handleOnRowSelectionModelChange : null) : null}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={(e) => console.log(e)}
        getRowClassName={getRowClassName}
        onRowClick={(row, event) => {
          if(!props.editGeometry && !(props.serverSyncState > 0)) {
            props.onRowSelectionModelChange(selectedCfg.uniqueKey, [row.id]);
          }
          event.defaultMuiPrevented = true;
          event.stopPropagation = true;
        }}

        onCellEditStart={(params, event) => {
          if(entryToMove != null) {
            event.defaultMuiPrevented = true;
          }
        }}
        onCellEditStop={(params, event) => {
          if (entryToMove != null) {
            event.defaultMuiPrevented = true;
          }
        }}

        autosizeOptions={{
          includeHeaders: false,
          includeOutliers: true,
          expand: true
        }}
        
        sx={{
          '& .MuiDataGrid-row:hover': {
            cursor: 'pointer'
          },
          [`& .${gridClasses.row}.row--removed`]: {
            backgroundColor: (theme) => {
              if (theme.palette.mode === 'light') {
                return 'rgba(255, 170, 170, 0.3)';
              }
              return darken('rgba(255, 170, 170, 1)', 0.7);
            },
          },

          [`& .${gridClasses.row}.row--edited`]: {
            backgroundColor: (theme) => {
              if (theme.palette.mode === 'light') {
                return 'rgba(255, 254, 176, 0.3)';
              }
              return darken('rgba(255, 254, 176, 1)', 0.6);
            },
          },
          [`& .${gridClasses.row}.row--edited:hover`]: {
            backgroundColor: (theme) => {
              if (theme.palette.mode === 'light') {
                return 'rgba(255, 254, 176, 0.6)';
              }
              return darken('rgba(255, 254, 176, 1)', 0.7);
            },
          },

          [`& .${gridClasses.row}.row--archived`]: {
            backgroundColor: (theme) => {
              if (theme.palette.mode === 'light') {
                return 'rgba(176, 241, 255, 0.3)';
              }
              return darken('rgba(176, 241, 255, 1)', 0.6);
            },
          },
          [`& .${gridClasses.row}.row--archived:hover`]: {
            backgroundColor: (theme) => {
              if (theme.palette.mode === 'light') {
                return 'rgba(176, 241, 255, 0.6)';
              }
              return darken('rgba(176, 241, 255, 1)', 0.7);
            },
          },

          ['& .button-cell']: {
            padding: "0px",
          },

          [`& .MuiDataGrid-cell input`]: {
            paddingInline: "10px",
          },
          [`& .MuiDataGrid-cell.${gridClasses['cell--editing']}`]: {
            boxShadow: "0px 0px 0px 0px rgba(0, 0, 0, 0.2)"
          }
        }}
      />

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {imageUrl ? (
          <div style={{width: 300, height: "auto", position: "relative"}}>
            <Box component="img" src={imageUrl.url} alt="Preview" sx={{width: 300, height: "auto"}}/>
            <div style={{position: "absolute", bottom: 0, left: 0, right: 0, background: "linear-gradient(to top, black, transparent)", backdropFilter: "blur(5px)", padding: 4, display: "flex", justifyContent: "space-between"}}>
              <IconButton style={{color: "white"}} onClick={(e) => handleFileDownload(imageUrl.params.value, BASE_URL, props.bearerToken, e)}><Download/></IconButton>
              <IconButton style={{color: "white"}} onClick={handleHidePicture}><Close/></IconButton>
            </div>
          </div>
        ) : (
          <Box p={2}>Loading...</Box>
        )}
      </Popover>

      <Menu open={contextMenu !== null} onClose={() => setContextMenu(null)} anchorReference="anchorPosition" anchorPosition={contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined} slotProps={{root: { onContextMenu: (e) => { e.preventDefault(); setContextMenu(null); }}}}>
        <MenuItem onClick={() => {setContextMenu(null); setShowDetails(true);}}>Detailansicht</MenuItem>
        <MenuItem onClick={() => {navigator.clipboard.writeText(selectedRow.textContent); setContextMenu(null);}}>Kopieren</MenuItem>
        {selectedColumn?.field === "number" ?
          <MenuItem onClick={() => {setContextMenu(null); setShowStatistic(true);}}>Statistik</MenuItem>
          :
          null
        }
      </Menu>

      <ImportDialog
        importDlgOpen={importDlgOpen}
        selectedCfg={selectedCfg}
        setImportDlgOpen={(open)  => setImportDlgOpen(open)}
        onEntryCreate={(entries) => createEntries(entries)}
      />

      <Dialog fullWidth maxWidth="sm" open={showDetails || showStatistic}>
        <DialogTitle>{showDetails ? "Detailansicht" : "Statistik - " + selectedColumn?.headerName}{showDetails ? <IconButton onClick={() => navigator.clipboard.writeText(selectedRow.textContent)}><CopyAll/></IconButton> : null}</DialogTitle>
        <DialogContent dividers>
          {showDetails ?
            <TextField multiline fullWidth rows={10} value={selectedRow ? selectedRow.textContent : ""}/>
            :
            <div>
              <TableContainer>
                <Table size="small" aria-label="simple table">
                  <TableBody>
                    {Object.entries(statisticData).map(([key, value]) => (
                      <TableRow key={key} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell style={{userSelect: "none", overflow: "hidden", width: "50%" }} align="left">{key}</TableCell>
                        <TableCell style={{userSelect: "none", width: "50%"}} align="left">{ (typeof value) == "object" ? JSON.stringify(value) : value}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            setShowDetails(false);
            setShowStatistic(false);
          }}>Ok</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}