import { Button, CircularProgress, Tooltip } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

function TimedButton(props) {
  const [timeLeft, setTimeLeft] = useState(props.timeout ?? 10);
  const [isDisabled, setIsDisabled] = useState(true);
  const { t } = useTranslation();

  console.log("loading", props.loading)

  useEffect(() => {
    if (timeLeft === 0) {
      setIsDisabled(false);
      return;
    }

    const timer = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, [timeLeft]);

  return (
    <Tooltip title={isDisabled ? "Sicherheit" : ""} placement='top'>
      <span>
        <Button {...props} disabled={isDisabled || props.loading}>
          {props.loading ?
            <CircularProgress
              size={24}
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          : null}
          {isDisabled ? `${t("wait")} ${timeLeft}` : props.children}
        }
        </Button>
      </span>
    </Tooltip>
  );
}

export default TimedButton;