import { useTheme } from '@emotion/react';
import { Add, Archive, ArchiveOutlined, CheckBoxOutlineBlank, Close, ContentCopyOutlined, CopyAll, DeleteForever, DeleteOutline, Download, EditRoad, FormatPaint, FormatPaintOutlined, Map, MapOutlined, OpenInNew, Place, PlaceOutlined, PostAddOutlined, Redo, Refresh, Restore, SaveOutlined, Undo, UploadFileOutlined, WarningAmberOutlined } from '@mui/icons-material';
import { Autocomplete, Box, Button, ButtonBase, Checkbox, Chip, CircularProgress, darken, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, IconButton, LinearProgress, Menu, MenuItem, Paper, Popover, Popper, Select, styled, Switch, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Tooltip, Typography } from '@mui/material';
import { DataGridPremium, GRID_CHECKBOX_SELECTION_COL_DEF, GridEditDateCell, GridEditInputCell, GridRowModes, GridToolbarContainer, GridToolbarQuickFilter, gridClasses, useGridApiContext, useGridApiRef } from '@mui/x-data-grid-premium';
import ImageIcon from '@mui/icons-material/Image';
import { memo, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { alphabeticSort, callApiAsync, convertToGeoJSON, fillTemplate,  geoJSONToKML, getShouldInvertFromHex, handleFileDownload, ObjectID, openUrl, toBase64 } from '../../js/helper';
import ExportButton from '../export/export';
import { SelectedPoiActions, useSelectedPoiList } from '../../context/SelectedPoiProvider';
import { muiTypes } from '../../js/defines';
import { useMap } from '../../context/MapProvider';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useTableController } from '../../context/TableProvider';
import { useServerData } from '../../providers/DataProvider';
import TimedButton from '../components/TimedButton';
import ImportDialog from './ImportDialog';
import { useAuth } from '../../providers/AuthProvider';

export const ParentEditCell = ({ id, value, field, api, parentOids }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const { userState } = useAuth();

  const { cfgs, activeCfgs, BASE_URL, setStatusMsg } = useServerData();

  const [selectedCfg, setSelectedCfg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [entries, setEntries] = useState([]);

  const [newParentOids, setNewParentOids] = useState(parentOids);


  async function fetchEntries() {
    let url = BASE_URL + `/api/Entries/GetById/${selectedCfg.id}`; 

    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + userState.token);
    myHeaders.append("Content-Type", "application/json");
    let requestOptions = {
      method: "POST",
      headers: myHeaders
    };

    setIsLoading(true);
    const response = await fetch(url, requestOptions);
    if (!response.ok) {
      console.log(`HTTP error! status: ${response.status}`)
      if(response.status == 404) {
        setStatusMsg({
          type: "Error",
          message: "404 - Not found: " + url
        });
      }

      let resp = await response.json();
      setStatusMsg({
        type: "Error",
        message: resp.error
      });
    }

    let data = await response.json();
    setEntries(data.data);
    setIsLoading(false);
  }

  useEffect(() => {
    if(selectedCfg) {
      fetchEntries();
    }
  }, [selectedCfg]);

  const handleSelectedIds = (item) => {
    let newItems = {...newParentOids};
    let multiple = false;

    if(multiple) {
      let idx = newItems[selectedCfg.uniqueKey]?.findIndex((i) => i == item);
      if(idx > -1) {
        newItems[selectedCfg.uniqueKey].splice(idx, 1);
      } else {
        if(!newItems[selectedCfg.uniqueKey]) {
          newItems[selectedCfg.uniqueKey] = []
        }
        newItems[selectedCfg.uniqueKey].push(item);
      }
    } else {
      if(!newItems[selectedCfg.uniqueKey]) {
        newItems[selectedCfg.uniqueKey] = []
      }
      newItems[selectedCfg.uniqueKey] = [item]
    }

    console.log(newItems);
    setNewParentOids(newItems);
  };

  const handleBack = () => {
    setSelectedCfg(null);
  };

  const handleSave = () => {
    api.setEditCellValue({ id, field, value: newParentOids });
    api.stopCellEditMode({ id, field });
  };

  useEffect(() => {
    const cell = api.getCellElement(id, field);
    if (cell) {
      setAnchorEl(cell);
    }
  }, [api, id, field]);

  const [searchText, setSearchText] = useState("");
  const filteredCfgs = useMemo(() => (
    cfgs.filter((i) => i.tableName.toLowerCase().includes(searchText.toLowerCase()))
  ), [cfgs, activeCfgs, searchText]);

  return (
    <Popover
      open={!!anchorEl}
      anchorEl={anchorEl}
      sx={{
        '& .MuiPaper-root': {
          pointerEvents: 'all',
        },
        pointerEvents: 'none',
      }}
      disableEnforceFocus={true}
      style={{
        pointerEvents: "none"
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      PaperProps={{
        elevation: 1,
        style: {
          minWidth: anchorEl?.offsetWidth,
          maxWidth: anchorEl?.offsetWidth * 2,
          padding: '8px',
        },
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Typography style={{textAlign: "center", fontWeight: "bold"}}>Edit Parent</Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            minHeight: 300,
            maxHeight: "50vh",
            textAlign: "center",
            paddingInline: 8,
            gap: 8
          }}
        >
          {selectedCfg == null ?
            <>
              <div style={{display: "flex", flexDirection: "column", overflowY: filteredCfgs.length > 0 ? "auto" : "hidden", overflowX: "hidden"}}>
                {filteredCfgs.length > 0 ? 
                  filteredCfgs?.sort(alphabeticSort).map((config) => (
                    <MenuItem key={config.uniqueKey} onClick={() => setSelectedCfg(config)} style={{gap: "8px"}} selected={newParentOids[config.uniqueKey]}>
                      <img src={config.tableIcon} style={{width: "24px", userSelect: "none", backgroundColor: config.tableIconBgColor, borderRadius: 6, padding: "1px"}}></img>
                      <Typography style={{textOverflow: "ellipsis", overflow: "hidden"}}>{`${newParentOids[config.uniqueKey]?.length ?? 0} - ${config.tableName}`}</Typography>
                    </MenuItem>
                  ))
                  :
                  <Typography style={{height: 48, alignContent: "center"}} fontSize={12}>{activeCfgs.length > 0 ? "No more modules" : "No modules"}</Typography>
              }
              </div>
              <TextField fullWidth size="small" label="Search" placeholder="1.1 Flächenbearbeitung" value={searchText} onChange={(e) => setSearchText(e.target.value)}/>
            </>
            :
            <>
              <div style={{display: "flex", flexDirection: "column", overflowY: filteredCfgs.length > 0 ? "auto" : "hidden", overflowX: "hidden"}}>
                {!isLoading ?
                  entries.length > 0 ? 
                    entries?.map((entry) => (
                      <MenuItem key={entry._id.$oid} onClick={() => handleSelectedIds(entry._id.$oid)} style={{gap: "8px"}} selected={newParentOids[selectedCfg.uniqueKey]?.includes(entry._id.$oid)}>
                        <Typography style={{textOverflow: "ellipsis", overflow: "hidden"}}>{fillTemplate(selectedCfg, selectedCfg?.headerLabelTemplate, entry)}</Typography>
                      </MenuItem>
                    ))
                    :
                    <Typography style={{height: 48, alignContent: "center"}} fontSize={12}>No Entries</Typography>
                  :
                  <div style={{height: "50vh", alignContent: "center"}}>
                    <CircularProgress/>
                  </div> 
                }
              </div>
              <TextField fullWidth size="small" label="Search" placeholder="1.1 Flächenbearbeitung" value={searchText} onChange={(e) => setSearchText(e.target.value)}/>
            </>
          }
        </div>
        <div style={{display: "flex", justifyContent: "space-evenly", gap: 8}}>
          <Button style={{width: "100%"}} onClick={selectedCfg ? handleBack : handleSave}>{selectedCfg ? "Zurück": "Speichern"}</Button>
        </div>
      </Box>
    </Popover>
  );
};

export const SignatureCell = ({ id, value, field, api }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const canvasRef = useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [drawingData, setDrawingData] = useState(value || null);

  const initializeCanvas = () => {
    console.log("INIT WHITE")
    const canvas = canvasRef.current;
    console.log(canvasRef.current)
    const ctx = canvas.getContext("2d");
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // Write the current date in the bottom-right corner
    const date = new Date().toLocaleDateString(); // Format date in local time
    ctx.fillStyle = "black"; // Set text color to black
    ctx.font = "12px Arial"; // Set font size and style
    const textWidth = ctx.measureText(date).width;
    ctx.fillText(date, canvas.width - textWidth - 5, canvas.height - 5); // Draw text in the bottom-right corner
  };

  const handleMouseDown = (e) => {
    setIsDrawing(true);
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    ctx.beginPath(); // Start a new path for the new stroke
  };

  const handleMouseUp = () => setIsDrawing(false);

  const handleMouseMove = (e) => {
    if (!isDrawing) return;

    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const rect = canvas.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    ctx.lineWidth = 2;
    ctx.lineCap = "round";
    ctx.strokeStyle = "black";

    ctx.lineTo(x, y);
    ctx.stroke();
    ctx.beginPath(); // Reset the path to avoid connecting strokes
    ctx.moveTo(x, y);
  };

  const handleClear = () => {
    api.setEditCellValue({ id, field, value: {} });
    api.stopCellEditMode({ id, field });
  };

  const handleSave = () => {
    const canvas = canvasRef.current;
    const dataURL = canvas.toDataURL("image/png");
    const date = new Date().toISOString().split("T")[0]; // YYYY-MM-DD
    const fileName = `Signature_${date}.png`;
    const savedValue = {
      FileName: fileName,
      base64: dataURL.split(",")[1], // Strip out the base64 header
      mimeType: "image/png",
    };
    setDrawingData(savedValue);
    api.setEditCellValue({ id, field, value: savedValue });
    api.stopCellEditMode({ id, field });
  };

  useEffect(() => {
    const cell = api.getCellElement(id, field);
    if (cell) {
      setAnchorEl(cell);
    }
  }, [api, id, field]);

  return (
    <Popover
      open={!!anchorEl}
      anchorEl={anchorEl}
      sx={{
        '& .MuiPaper-root': {
          pointerEvents: 'all',
        },
        pointerEvents: 'none',
      }}
      disableEnforceFocus={true}
      style={{
        pointerEvents: "none"
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      PaperProps={{
        elevation: 1,
        style: {
          minWidth: anchorEl?.offsetWidth * 1.2 || 200, // Match the cell's width
          padding: '8px',
        },
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Typography style={{textAlign: "center", fontWeight: "bold"}}>Edit Signature</Typography>
        <canvas
          ref={(el) => {
            if (el && !canvasRef.current) {
              canvasRef.current = el;
              initializeCanvas(el);
            }
          }}
          width="400"
          height="150"
          style={{ border: "1px solid #999", cursor: "crosshair", borderRadius: 8 }}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={() => setIsDrawing(false)}
        ></canvas>
        <div style={{display: "flex", justifyContent: "space-evenly", gap: 8}}>
          <IconButton onClick={handleClear}><DeleteOutline/></IconButton>
          <IconButton onClick={() => {
            const elem = document.createElement("input");
            elem.type = "file";
            elem.accept = "image/jpeg,image/png";
            elem.addEventListener("change", async () => {
              if (elem.files.length == 1) {
                let base64 = await toBase64(elem.files[0]);
                let newValue = {
                  "FileName": elem.files[0].name,
                  "base64": base64.split(",")[1],
                  "mimeType": elem.files[0].type,
                }
                api.setEditCellValue({ id, field, value: newValue });
                api.stopCellEditMode({ id, field });
              }
            });
            elem.click();
          }}><UploadFileOutlined sx={{fontSize: "18px"}}/></IconButton>
          <Button style={{width: "100%"}} onClick={handleSave}>Save</Button>
        </div>
      </Box>
    </Popover>
  );
};

export function CustomEditInputCell(props) {
  const { id, field, value, api } = props;

  const handleChange = (event) => {
    const newValue = event.target.value;
    //if (/^\d*$/.test(newValue)) {
      api.setEditCellValue({ id, field, value: newValue }, event);
    //}
  };


  const handleKeyDown = (event) => {
    const invalidChars = ["e", "E", "+", "-", ".", ","];
    let matches = invalidChars.includes(event.key)
    console.log(matches)
    if (matches) {
      event.preventDefault();
    }
  };

  const handlePaste = (event) => {
    const paste = event.clipboardData.getData("text");

    // Allow only digits
    if (!/^\d+$/.test(paste)) {
      event.preventDefault(); // Block the paste if it contains non-digit characters
    }
  }

  return (
    <GridEditInputCell
      {...props}
      inputProps={{
        inputMode: "numeric", // For mobile number keypad
        pattern: "[0-9]*",   // Enforce digits only
      }}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      onPaste={handlePaste}
    />
  );
};

export function CustomBooleanEditComponent(props) {
  const { id, value, field, hasFocus, column } = props;
  const apiRef = useGridApiContext();
  const ref = useRef();

  useLayoutEffect(() => {
    if (hasFocus && ref) {
      ref.current.focus();
    }
  }, [hasFocus, ref]);

  return <Checkbox ref={ref} size='small' checked={value} onChange={(e, newValue) => {console.log(newValue); apiRef.current.setEditCellValue({ id, field, value: newValue});}}/>
}

export function CustomVectorOverlayEditComponent(props) {
  const { id, value, field, hasFocus, column, accept, userState, bearerToken } = props;
  const apiRef = useGridApiContext();

  return <>
    {value ? <IconButton style={{verticalAlign: "sub", alignContent: "center"}} onClick={(e) => handleFileDownload(value, userState, bearerToken, e)}><Download sx={{fontSize: "18px"}}/></IconButton> : null}
    <IconButton onClick={() => {
      const elem = document.createElement("input");
      elem.type = "file";
      elem.accept = accept ? accept : null;
      elem.addEventListener("change", async () => {
        if (elem.files.length == 1) {
          var reader = new FileReader();
          reader.readAsText(elem.files[0], "UTF-8");
          reader.onload = function (evt) {
            let geojson = convertToGeoJSON(evt.target.result);
            apiRef.current.setEditCellValue({ id, field, value: geojson });
          }
        }
      });
      elem.click();
    }}><UploadFileOutlined sx={{fontSize: "18px"}}/></IconButton>
    {value ? "Map" : ""}
  </>
}

export function CustomFileEditComponent(props) {
  const { id, value, field, hasFocus, column, accept, userState, bearerToken } = props;
  const apiRef = useGridApiContext();

  return <>
    {value?.FileUrl ? <IconButton style={{verticalAlign: "sub", alignContent: "center"}} onClick={(e) => handleFileDownload(value, userState, bearerToken, e)}><Download sx={{fontSize: "18px"}}/></IconButton> : null}
    <IconButton onClick={() => {
      const elem = document.createElement("input");
      elem.type = "file";
      elem.accept = accept ? accept : null;
      elem.addEventListener("change", async () => {
        if (elem.files.length == 1) {
          let base64 = await toBase64(elem.files[0]);
          let newValue = {
            "FileName": elem.files[0].name,
            "base64": base64.split(",")[1],
            "mimeType": elem.files[0].type,
          }
          apiRef.current.setEditCellValue({ id, field, value: newValue });
        }
      });
      elem.click();
    }}><UploadFileOutlined sx={{fontSize: "18px"}}/></IconButton>
    <IconButton style={{verticalAlign: "sub", alignContent: "center"}} onClick={(e) => apiRef.current.setEditCellValue({ id, field, value: {} })}><DeleteOutline sx={{fontSize: "18px"}}/></IconButton>
    {value?.FileName ?? ""}
  </>
}

export function CustomImageEditComponent(props) {
  const { id, value, field, hasFocus, column, accept, userState, bearerToken } = props;
  const apiRef = useGridApiContext();

  return <>
    {value?.FileUrl ? <IconButton style={{verticalAlign: "sub", alignContent: "center"}} onClick={(e) => handleFileDownload(value, userState, bearerToken, e)}><ImageIcon sx={{fontSize: "18px"}}/></IconButton> : null}
    <IconButton onClick={() => {
      const elem = document.createElement("input");
      elem.type = "file";
      elem.accept = accept ? accept : null;
      elem.addEventListener("change", async () => {
        if (elem.files.length == 1) {
          console.log("File selected: ", elem.files[0]);
          console.log(id, field, elem.files[0]);
          let base64 = await toBase64(elem.files[0]);
          let newValue = {
            "FileName": elem.files[0].name,
            "base64": base64.split(",")[1],
            "mimeType": elem.files[0].type,
          }
          apiRef.current.setEditCellValue({ id, field, value: newValue });
        }
      });
      elem.click();
    }}><UploadFileOutlined sx={{fontSize: "18px"}}/></IconButton>
    <IconButton style={{verticalAlign: "sub", alignContent: "center"}} onClick={(e) => apiRef.current.setEditCellValue({ id, field, value: {} })}><DeleteOutline sx={{fontSize: "18px"}}/></IconButton>
    {value?.FileName ?? ""}
  </>
}

export function CustomSingleSelectEditComponent(props) {
  const { id, value, field, hasFocus, column } = props;
  const [open, setOpen] = useState(true);
  const apiRef = useGridApiContext();
  const ref = useRef();

  useLayoutEffect(() => {
    if (hasFocus && ref) {
      ref.current.focus();
    }
  }, [hasFocus, ref]);

  let configurationsJson;
  let selected;
  if(column.configurationsJson != null && column.configurationsJson != "") {
    configurationsJson = JSON.parse(column.configurationsJson);
    selected = configurationsJson.listItems ? configurationsJson.listItems.sort((a, b) => a.key - b.key).find((option) => option.key == value) : ""
  } 

  return <Autocomplete
    ref={ref}
    fullWidth
    open={open}
    onOpen={(e) => setOpen(!open)}
    style={{paddingInline: "10px"}}
    size='small'
    options={configurationsJson?.listItems ?? []}
    value={selected}
    getOptionLabel={(option) => option.value}
    onChange={(e, value) => {console.log(value); apiRef.current.setEditCellValue({ id, field, value: value ? value.key : "" }); setOpen(false);}}
    renderInput={(params) => <TextField fullWidth variant='standard' size='small' {...params} InputProps={{...params.InputProps, disableUnderline: true, style: {...params.InputProps.style, fontSize: "0.875rem", padding: "3px 56px 1px 1px"}}} style={{marginTop: "1px"}}/>}
  />
}

export function CustomMultiSelectEditComponent(props) {
  const { id, value, field, hasFocus, colDef, column } = props;
  const apiRef = useGridApiContext();
  const [anchorEl, setAnchorEl] = useState();
  const [inputRef, setInputRef] = useState(null);
  const ref = useRef();

  useLayoutEffect(() => {
    if (hasFocus && inputRef) {
      inputRef.focus();
    }
  }, [hasFocus, ref, inputRef]);

  const handleRef = useCallback((el) => {
    setAnchorEl(el);
  }, []);

  let configurationsJson;
  let selected;
  if(column.configurationsJson != null && column.configurationsJson != "") {
    configurationsJson = JSON.parse(column.configurationsJson);
    selected = configurationsJson.listItems ? configurationsJson?.listItems.filter((entry) => value ? value.includes(entry.key) : false) : [];
  } 

  return(
    <div style={{ position: 'relative', alignSelf: 'flex-start' }}>
      <div
        ref={handleRef}
        style={{
          height: 1,
          width: colDef.computedWidth,
          display: 'block',
          position: 'absolute',
          top: 0,
        }}
      />
      {anchorEl && (
        <Popper open anchorEl={anchorEl} placement="bottom" style={{zIndex: 3}}>
          <Paper elevation={1} sx={{width: colDef.computedWidth, minHeight: "36px"}}>
            <Autocomplete
              multiple
              ref={ref}
              disableCloseOnSelect
              openOnFocus={true}
              style={{paddingInline: "10px"}}
              size='small'
              options={configurationsJson?.listItems ?? []}
              value={selected}
              getOptionLabel={(option) => option.value}
              ChipProps={{color: "default"}}
              onChange={(e, values) => {
                let newValue = values ? values.map((value) => value.key ? value.key : value) : "";
                apiRef.current.setEditCellValue({ id, field, value: newValue });
              }}
              renderInput={(params) => <TextField fullWidth variant='standard' size='small' {...params} InputProps={{...params.InputProps, disableUnderline: true}} style={{marginTop: "3px"}}/>}
              renderOption={(props, option, { selected }) => {
                const { key, ...optionProps } = props;
                return (
                  <li key={key} {...optionProps} style={{paddingBlock: "0px", paddingInline: "10px"}}>
                    <Checkbox
                      size='small'
                      icon={<CheckBoxOutlineBlank fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.value}
                  </li>
                );
              }}
            />
          </Paper>
        </Popper>
      )}
    </div>
  );
}

export function CustomDynamicSelectEditComponent(props) {
  const { id, value, field, hasFocus, colDef, column } = props;
  const apiRef = useGridApiContext();
  const [anchorEl, setAnchorEl] = useState();
  const [inputRef, setInputRef] = useState(null);
  const { cfgs, dataEntries } = useServerData();
  const ref = useRef();

  useLayoutEffect(() => {
    if (hasFocus && inputRef) {
      inputRef.focus();
    }
  }, [hasFocus, ref, inputRef]);

  const handleRef = useCallback((el) => {
    setAnchorEl(el);
  }, []);

  let configurationsJson;
  let listItems;
  let selected;
  if(column.configurationsJson != null && column.configurationsJson != "") {
    configurationsJson = JSON.parse(column.configurationsJson);
    let dynamicCfg = cfgs.find((i) => i.id == configurationsJson.tableId);
    listItems = dataEntries[dynamicCfg?.uniqueKey]?.map((i) => ({"key": i._id.$oid, "value": fillTemplate(dynamicCfg, dynamicCfg.headerLabelTemplate, i)})) ?? [];
    selected = listItems?.filter((entry) => value?.includes(entry.key) ?? false) ?? [];
  }

  return(
    <div style={{ position: 'relative', alignSelf: 'flex-start' }}>
      <div
        ref={handleRef}
        style={{
          height: 1,
          width: colDef.computedWidth,
          display: 'block',
          position: 'absolute',
          top: 0,
        }}
      />
      {anchorEl && (
        <Popper open anchorEl={anchorEl} placement="bottom" style={{zIndex: 3}}>
          <Paper elevation={1} sx={{width: colDef.computedWidth, minHeight: "36px"}}>
            <Autocomplete
              multiple
              ref={ref}
              disableCloseOnSelect
              openOnFocus={true}
              style={{paddingInline: "10px"}}
              size='small'
              options={listItems ?? []}
              value={selected}
              getOptionLabel={(option) => option.value}
              ChipProps={{color: "default"}}
              onChange={(e, values) => {
                let newValue = values.map((value) => value.key)
                apiRef.current.setEditCellValue({ id, field, value: newValue });
              }}
              renderInput={(params) => <TextField fullWidth variant='standard' size='small' {...params} InputProps={{...params.InputProps, disableUnderline: true}} style={{marginTop: "3px"}}/>}
              renderOption={(props, option, { selected }) => {
                const { key, ...optionProps } = props;
                return (
                  <li key={key} {...optionProps} style={{paddingBlock: "0px", paddingInline: "10px"}}>
                    <Checkbox
                      size='small'
                      icon={<CheckBoxOutlineBlank fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.value}
                  </li>
                );
              }}
            />
          </Paper>
        </Popper>
      )}
    </div>
  );
}

export function CustomAssignedUsersEditComponent(props) {
  const { buddies } = useServerData();
  const { id, value, field, hasFocus, colDef, column } = props;
  const apiRef = useGridApiContext();
  const [anchorEl, setAnchorEl] = useState();
  const [inputRef, setInputRef] = useState(null);
  const ref = useRef();

  useLayoutEffect(() => {
    if (hasFocus && inputRef) {
      inputRef.focus();
    }
  }, [hasFocus, ref, inputRef]);

  const handleRef = useCallback((el) => {
    setAnchorEl(el);
  }, []);

  let selected = buddies.filter((buddy) => value ? value.includes(buddy.id) : false);

  return(
    <div style={{ position: 'relative', alignSelf: 'flex-start' }}>
      <div
        ref={handleRef}
        style={{
          height: 1,
          width: colDef.computedWidth,
          display: 'block',
          position: 'absolute',
          top: 0,
        }}
      />
      {anchorEl && (
        <Popper open anchorEl={anchorEl} placement="bottom" style={{zIndex: 3}}>
          <Paper elevation={1} sx={{width: colDef.computedWidth, minHeight: "36px"}}>
            <Autocomplete
              multiple
              ref={ref}
              disableCloseOnSelect
              openOnFocus={true}
              style={{paddingInline: "10px"}}
              size='small'
              options={buddies ?? []}
              value={selected}
              getOptionLabel={(option) => option.fullname}
              ChipProps={{color: "default"}}
              onChange={(e, values) => {
                let newValue = values ? values.map((value) => value.id ? value.id : value) : "";
                apiRef.current.setEditCellValue({ id, field, value: newValue });
              }}
              renderInput={(params) => <TextField fullWidth variant='standard' size='small' {...params} InputProps={{...params.InputProps, disableUnderline: true}} style={{marginTop: "3px"}}/>}
              renderOption={(props, option, { selected }) => {
                const { key, ...optionProps } = props;
                return (
                  <li key={key} {...optionProps} style={{paddingBlock: "0px", paddingInline: "10px"}}>
                    <Checkbox
                      size='small'
                      icon={<CheckBoxOutlineBlank fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.fullname}
                  </li>
                );
              }}
            />
          </Paper>
        </Popper>
      )}
    </div>
  );
}

export function CustomLocationEditComponent(props) {
  const { id, field, value, api } = props;
  const [lat, setLat] = useState(value?.lat || '');
  const [lon, setLon] = useState(value?.long || '');
  const [coordinateSystem, setCoordinateSystem] = useState('decimal_degrees'); // Default system
  const [anchorEl, setAnchorEl] = useState(null);


  const [searchText, setSearchText] = useState("");
  const [foundPlaces, setFoundPlaces] = useState([]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      let requestOptions = {
        method: "GET",
      };
      callApiAsync("https://nominatim.openstreetmap.org/search?format=json&q=" + searchText, requestOptions, (resp) => {
        setFoundPlaces(resp);
      });
    }, 200)
    return () => clearTimeout(delayDebounceFn)
  }, [searchText])


  const { entryToMove, setEntryToMove, setPosCallback } = useMap();

  const handleLatChange = (e) => {
    setLat(e.target.value);
    updateValue(e.target.value, lon);
  };

  const handleLonChange = (e) => {
    setLon(e.target.value);
    updateValue(lat, e.target.value);
  };

  const updateValue = (lat, lon) => {
    setLat(lat);
    setLon(lon);
  };

  const handleSubmit = () => {
    setEntryToMove(null);
    setAnchorEl(null);

    const newValue = {
      lat: parseFloat(lat),
      long: parseFloat(lon)
    };
    api.setEditCellValue({ id, field, value: newValue });

    api.stopCellEditMode({ id, field })
  };

  useEffect(() => {
    const cell = api.getCellElement(id, field);
    if (cell) {
      setAnchorEl(cell);
    }
  }, [api, id, field]);
  
  useEffect(() => {
    setPosCallback(updateValue);
    setEntryToMove(id)
  }, []);

  return (
    <Popover
      open={!!anchorEl}
      anchorEl={anchorEl}
      sx={{
        '& .MuiPaper-root': {
          pointerEvents: 'all',
        },
        pointerEvents: 'none',
      }}
      disableEnforceFocus={true}
      style={{
        pointerEvents: "none"
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      PaperProps={{
        elevation: 1,
        style: {
          width: anchorEl?.offsetWidth * 1.2 || 200, // Match the cell's width
          padding: '8px',
        },
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Typography style={{textAlign: "center", fontWeight: "bold"}}>Edit Location</Typography>
        <Autocomplete
          size='small'
          loading={false}
          includeInputInList
          options={foundPlaces}
          getOptionLabel={(option) => option?.display_name}
          onInputChange={(e) => setSearchText(e.target.value)}
          onChange={(event, newValue) => {
            if(newValue?.lat && newValue?.lon) {
              setLat(newValue.lat)
              setLon(newValue.lon)
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Ortssuche"
              slotProps={{
                input: {
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {false ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                },
              }}
            />
          )}
        />

        <Select
          value={coordinateSystem}
          onChange={(e, value) => console.log(e.target.value)}
          disabled
          variant="outlined"
          size="small"
          fullWidth
        >
          <MenuItem value="decimal_degrees">Decimal Degrees</MenuItem>
          <MenuItem value="degrees_minutes_seconds">Degrees Minutes Seconds</MenuItem>
        </Select>
        <TextField
          label="Latitude"
          value={lat}
          onChange={handleLatChange}
          variant="outlined"
          size="small"
          fullWidth
        />
        <TextField
          label="Longitude"
          value={lon}
          onChange={handleLonChange}
          variant="outlined"
          size="small"
          fullWidth
        />
        <div style={{display: "flex", justifyContent: "space-evenly", gap: 8}}>
          <Button style={{width: "100%"}} onClick={handleSubmit}>Save</Button>
        </div>
      </Box>
    </Popover>
  );
}